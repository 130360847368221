import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import Favourites from './Favourites'
import Checkout from './Checkout'
import Cart from './cart'
import ProductAuction from './ProductAuction'
import Invoice from './Invoices/Invoice'
import ChangePassword from './ChangePassword'
import faq from './StaticPages/faq'
import howItWorks from './StaticPages/howItWorks'
import privacy_policy from './StaticPages/privacypolicy'
import aboutus from './StaticPages/aboutus'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Registration from './Registration'
import Activateaccount from './Activateaccount'
import Home from './Home'
import Search from './Search'
import AuctionSearch from './AuctionSearch'
import Gallery from './Gallery'
import Profile from './Profile'
import Dashboard from './Dashboard'
import Transactions from './Transactions'
import Cards from './cards'
import authContext from '../../Product/context/auth/authContext'
import termsOfService from './StaticPages/termsOfService'
import ProductView from './ProductView'
import Auctions from './Auctions'
import { commonPaths } from '../../utils/commonFunctions'
import MaintenanceMode from './MaintenanceMode'
import LiveAuctionView from './LiveAuction/LiveAuctionView'
import LiveAuction from './LiveAuction'
import Static from './Static'
import Ticketing from '../../utils/CommonFunctionality/Ticketing'
import EventAuction from './EventAuction'
import mytickets from './MYTickets'
import ContactUs from './ContactUs'
import IdleTimerContainer from '../../utils/IdleTimerContainer'
import Preference from './Preference'
import Referral from './Referral'
import Paypalsuccess from './Paypalpaymentsucess'
import Paypalcancel from './Paypalpaymentcancel'
import Subscription from './Subscription'
import Billing from './Subscription/Billing'
import Pos from './Pos'
import PosLocation from '../../utils/CommonFunctionality/Pos/Location'
import PosReaders from '../../utils/CommonFunctionality/Pos/Readers'
import PosCheckout from './Pos/Checkout'
import AuctionCart from './AuctionCart'
import ReverseAuctions from './Reverse Auctions'
const PrivateRoute = ({ component: Component, ...rest }) => (
    <>
        <Route
            {...rest}
            render={(props) =>
                localStorage.token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    </>
)

export default function LiquidationRoutes() {
    return (
        <>
            <Route exact path="/maintenance" component={MaintenanceMode} />
            <Route exact path="/" component={Home} />
            <Route exact path="/faq" component={faq} />
            <Route exact path="/aboutus" component={aboutus} />
            <Route exact path="/termsOfService" component={termsOfService} />
            <Route exact path="/how_it_works" component={howItWorks} />
            <Route exact path="/search" component={AuctionSearch} />
            <Route exact path="/product/:id" component={ProductView} />
            <Route exact path="/auctions" component={Auctions} />
            <Route exact path="/reverse-auction" component={ReverseAuctions} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/search/autologin/:login_email" component={Login} />
            <Route exact path="/autologin/:login_email" component={Login} />
            <Route exact path="/search/auto-login/:login_email" component={Login} />
            <Route exact path="/auto-login/:login_email" component={Login} />
            <Route exact path="/auto-login-pos/:login_email" component={Login} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/sign-up" component={Registration} />
            <Route exact path="/activateaccount" component={Activateaccount} />
            <Route exact path="/login/forgot_password" component={ChangePassword} />
            <Route exact path="/search/product-buyer-auction/:id" component={ProductAuction} />
            <Route exact path="/eventAuction/:id" component={EventAuction} />
            <Route exact path="/changepasswordusers" component={ChangePassword} />
            <Route exact path="/ticketing/:sid/:aid" component={Ticketing} />
            <PrivateRoute exact path={commonPaths.profile} component={Profile} />
            <PrivateRoute exact path={commonPaths.mybids} component={Dashboard} />
            <PrivateRoute exact path="/dashboard/mybids/:status" component={Dashboard} />
            <PrivateRoute exact path="/dashboard/preference" component={Preference} />
            <PrivateRoute exact path="/dashboard/billing" component={Billing} />
            <PrivateRoute
                exact
                path={commonPaths.cart}
                component={window.location.href.includes('pos') ? PosCheckout : Cart}
            />
            <PrivateRoute exact path={commonPaths.auctionCart} component={AuctionCart} />
            {/* {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                <PrivateRoute exact path={commonPaths.cart} component={Cart} />
            ) : (
                <Redirect to="/" />
            )} */}
            <PrivateRoute exact path={commonPaths.checkout} component={Checkout} />
            <PrivateRoute exact path={commonPaths.invoice} component={Invoice} />
            <PrivateRoute exact path={commonPaths.transaction} component={Transactions} />
            <PrivateRoute exact path={commonPaths.cards} component={Cards} />
            <PrivateRoute exact path={commonPaths.favorites} component={Favourites} />
            <PrivateRoute exact path={'/dashboard/subscription'} component={Subscription} />
            <PrivateRoute
                exact
                path={'/checkout/:auction/paypalsuccess'}
                component={Paypalsuccess}
            />
            <PrivateRoute exact path={'/checkout/:auction/paypalcancel'} component={Paypalcancel} />
            <PrivateRoute exact path="/dashboard/mytickets" component={mytickets} />
            <Route exact path="/liveauctionview/:id" component={LiveAuctionView} />
            <PrivateRoute exact path="/liveLots" component={LiveAuction} />
            <Route exact path="/privacy_policy" component={privacy_policy} />
            <Route exact path="/return_policy" component={Static} />
            <Route exact path="/contact_us" component={ContactUs} />
            <PrivateRoute exact path="/referral" component={Referral} />
            {global?.storeConfigration?.session_timeout ? <IdleTimerContainer /> : ''}

            <PrivateRoute exact path="/dashboard/pos" component={Pos} />
            <PrivateRoute exact path="/dashboard/pos/location" component={PosLocation} />
            <PrivateRoute exact path="/dashboard/pos/readers" component={PosReaders} />
            <PrivateRoute exact path="/pos-checkout/:type" component={PosCheckout} />
        </>
    )
}
