import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Popup from '../../../Product/components/organisms/Popup'
import { Copyrightinfo, FooterLogo } from '../../../utils'
import MetaTag from '../../../utils/CommonFunctionality/SEO/MetaTag'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import Footernav from '../../../utils/FooterNavigation/footernav'
import GoogleMap from '../../../utils/CommonFunctionality/GoogleMap'
import facebookImg from '../../../assets/images/facebook.svg'
import tiktokImg from '../../../assets/images/tiktok.svg'
import youtubeImg from '../../../assets/images/youtube.svg'
import instagramImg from '../../../assets/images/instagram.svg'
import linkedinImg from '../../../assets/images/linkedin.svg'
import twitterImg from '../../../assets/images/x.svg'
import './Footer.css'
function Footer() {
    const [openModal, setModal] = useState(false)
    const [anchormenu, setanchormenu] = useState(null)

    const changeDialogStatus = () => {
        setModal(!openModal)
    }
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagram = global?.storeDetails?.instagram
    return (
        <footer className="footer-main-standard noPrint">
            <div className="container">
                <div className="ftrWrprVw">
                    <div className="lgAdCntnr">
                        <div className="ftrLgCntnr">
                            <FooterLogo className="footerLogo" />
                        </div>
                        {global.storeConfigration?.meta_tag_pixel_pinacle?.value ? <MetaTag /> : ''}
                        <div className="footer-copy-right">
                            <Copyrightinfo />
                        </div>
                        {/* <div className="adrsFlds d-md-block d-none">
                            <p>
                                {global?.storeSeller?.address1}{' '}
                                {global?.storeSeller?.address2 != '' &&
                                    global?.storeSeller?.address2 != null && (
                                        <>
                                            <br />
                                            {global?.storeSeller?.address2}
                                        </>
                                    )}
                                <br />
                                {global?.storeSeller?.city}, {global?.storeSeller?.state}
                                <br />
                                {global?.storeSeller?.country}, {global?.storeSeller?.zip}
                            </p>
                        </div> */}
                        <div>
                            <p className="followSocial">Follow us on social media!</p>
                            <ul className="socially">
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={facebookImg} alt="Facebook" />
                                    </Link>
                                </li>
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={tiktokImg} alt="Tiktok" />
                                    </Link>
                                </li>
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={youtubeImg} alt="Youtube" />
                                    </Link>
                                </li>
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={instagramImg} alt="Instagram" />
                                    </Link>
                                </li>
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={linkedinImg} alt="LinkedIn" />
                                    </Link>
                                </li>
                                <li className="facebook">
                                    <Link to="/">
                                        <img src={twitterImg} alt="Twitter" />
                                    </Link>
                                </li>
                                {/* {facebookLink && (
                                    <li className="facebook">
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                    </li>
                                )}
                                {twitterLink && (
                                    <li className="pinterest">
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Twitter"
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                )}
                                {googleLink && (
                                    <li className="instagram">
                                        <a
                                            href={googleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                        >
                                            Linkedin
                                        </a>
                                    </li>
                                )}
                                {instagram && (
                                    <li className="instagram">
                                        <a
                                            href={instagram}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                )} */}
                            </ul>
                        </div>
                    </div>
                    <div className="lnkGrdTb">
                        <h4>Help</h4>
                        <ul className="footerLinks">
                            <li>
                                <NavLink activeClassName="active" to="/how_it_works">
                                    How it works
                                </NavLink>
                            </li>
                            {global.storeDetails?.terms &&
                            global.storeConfigration?.disable_faq?.value !== '1' ? (
                                <li>
                                    <NavLink activeClassName="active" to="/faq">
                                        FAQs
                                    </NavLink>
                                </li>
                            ) : (
                                ''
                            )}
                            {/* <li>
                                <NavLink activeClassName="active" to="/how_it_works">
                                    Shipping Policy
                                </NavLink>
                            </li> */}

                            <Footernav />
                        </ul>
                    </div>

                    <div className="lnkGrdTb">
                        <h4>About</h4>
                        <ul className="footerLinks">
                            {global.storeDetails?.privacy && (
                                <li>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li>
                            )}
                            {global.storeDetails?.terms_condition && (
                                <li>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        Terms & Conditions
                                    </NavLink>
                                </li>
                            )}
                            {/* {global.storeDetails.privacy_policy ? (
                                <li>
                                    <NavLink activeClassName="active" to="/privacy_policy">
                                        Privacy Policy
                                    </NavLink>
                                </li>
                            ) : (
                                ''
                            )}
                            {global.storeConfigration?.contact_us?.value ? (
                                <li>
                                    <NavLink activeClassName="active" to="/contact_us">
                                        Job Opportunities
                                    </NavLink>
                                </li>
                            ) : (
                                ''
                            )} */}
                        </ul>
                    </div>

                    <div className="lnkGrdTb">
                        <h4>Help & Contact</h4>
                        <ul className="footerLinks">
                            {contactEmail && (
                                <li>
                                    <a href={`mailto:${contactEmail}`} className="cvc-link">
                                        {contactEmail}
                                    </a>
                                </li>
                            )}
                            {global.storeConfigration?.hide_phone?.value != 1 && contactPhone && (
                                <li>
                                    <a href={`tel:${contactPhone}`} className="cvc-link">
                                        {contactPhone}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="lnkGrdTb d-md-none">
                        <h4>ADDRESS</h4>
                        <div className="adrsFlds">
                            <p>
                                {global?.storeSeller?.address1}{' '}
                                {global?.storeSeller?.address2 != '' &&
                                    global?.storeSeller?.address2 != null && (
                                        <>
                                            <br />
                                            {global?.storeSeller?.address2}
                                        </>
                                    )}
                                <br />
                                {global?.storeSeller?.city}, {global?.storeSeller?.state}
                                <br />
                                {global?.storeSeller?.country}, {global?.storeSeller?.zip}
                            </p>
                        </div>
                    </div>
                    {global.storeConfigration?.footer_map?.value ? (
                        <div className="myMap">
                            <GoogleMap />
                        </div>
                    ) : null}
                </div>
            </div>

            <Popup
                open={openModal}
                size="md"
                handleClose={changeDialogStatus}
                modaltitle="Schedule Pickup"
            >
                <div className="schedulePickup">
                    <iframe
                        height={600}
                        src={global.storeConfigration?.external_schedule_pickup_link?.value}
                    ></iframe>
                </div>
            </Popup>
        </footer>
    )
}

export default Footer
