import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from '@material-ui/core'
// import ContentCopy from '@material-ui/icons/copy'
const Copy = ({ text, textOnly, setAlert }) => {
    return (
        <CopyToClipboard
            onCopy={() => setAlert('Product copied to Clipboard', 'success')}
            text={
                textOnly
                    ? text
                    : window.location.href.includes(global?.storeDetails?.id)
                    ? `${window.location.origin}/${global?.storeDetails?.id}${text}`
                    : `${window.location.origin}${text}`
            }
        >
            <Tooltip title="Copy To Clipboard" arrow={true} placement="top">
                <span className="material-icons" style={{ cursor: 'pointer' }}>
                    content_copy
                </span>
            </Tooltip>
        </CopyToClipboard>
    )
}

export default Copy
