import React, { useState, useEffect, useContext, useRef } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { authenticationService } from '../Services'
import { Redirect } from 'react-router'

import axios from 'axios'
import AuctioneerRoutes from '../Themes/Auctioneer/routes'
import LiquidationRoutes from '../Themes/Liquidation/routes'
import MultiSellerRoutes from '../Themes/MultiSeller/routes'
import HeavyEquipmentRoutes from '../Themes/HeavyEquipment/routes'

import ProductContext from '../Product/context/product/productContext'
import AuthContext from '../Product/context/auth/authContext'
import CartContext from '../Product/context/cart/cartContext'
import StripeCardContext from '../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../Product/context/directStripe/directStripeContext'
import FundraiserRoutes from '../Themes/Fundraiser/routes'
import AutomobileRoutes from '../Themes/Automobile/routes'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import MultiSeller_two_Routes from '../Themes/MultiSeller_two/routes'
import SEO from '../utils/CommonFunctionality/SEO'
import FoodIndustry_Routes from '../Themes/FoodIndustry/routes'
import { socket, socketAuction } from '../Product/common/socket'
import MultiAuction_Routes from '../Themes/MultiAuction/routes'
import { Helmet } from 'react-helmet-async'
import LiveStock_Routes from '../Themes/LiveStock/routes'
import ArtAuction_Routes from '../Themes/ArtAuction/routes'
import LiquidationPalletRoutes from '../Themes/LiquidationPallet/routes'
import LiquidationTwoRoutes from '../Themes/Liquidation_two/routes'
import CarAuctionRoutes from '../Themes/CarAuction/routes'
import { english_text } from '../utils/CommonFunctionality/LanguageManager/defauttext'
import { useTranslation } from 'react-i18next'
import CollectiblesRoutes from '../Themes/Collectibles/routes'
import MarineRoutes from '../Themes/Marine/routes'
import jwtDecode from 'jwt-decode'
import { useSnackbar } from 'notistack'
import buyerContext from '../Product/context/buyer/buyerContext'
import LiquidationThreeRoutes from '../Themes/Liquidation_three/routes'
const getBasename = (path) => {
    let subPath = path.substr(0, path.lastIndexOf('/'))
    let baseID = null
    if (subPath.split('/').length > 1) {
        let subPathChanged = subPath.split('/')[1]
        if (!isNaN(subPathChanged)) {
            baseID = parseInt(subPathChanged, 10)
        } else {
        }
    }
    return baseID
}

const getTheme = (themeID) => {
    let themeValue = 'Auctioneer'
    if (themeID) {
        if (themeID === 1) {
            themeValue = 'Auctioneer'
        } else if (themeID === 2) {
            themeValue = 'Liquidation'
        } else if (themeID === 3) {
            themeValue = 'MultiSeller'
        } else if (themeID === 4) {
            themeValue = 'HeavyEquipment'
        } else if (themeID === 5) {
            themeValue = 'Fundraiser'
        } else if (themeID === 6) {
            themeValue = 'CarAuction'
        } else if (themeID === 7) {
            themeValue = 'MultiSeller_two'
        } else if (themeID === 8) {
            themeValue = 'FoodIndustry'
        } else if (themeID === 9) {
            themeValue = 'MultiAuction'
        } else if (themeID === 10) {
            themeValue = 'LiveStock'
        } else if (themeID === 11) {
            themeValue = 'ArtAuction'
        } else if (themeID === 12) {
            themeValue = 'LiquidationPallet'
        } else if (themeID === 13) {
            themeValue = 'liquidationTwo'
        } else if (themeID === 14) {
            themeValue = 'Collectibles'
        } else if (themeID === 15) {
            themeValue = 'Marine'
        } else if (themeID === 18) {
            themeValue = 'LiquidationThree'
        }
        //console.log(themeID, 'themeID')
    }
    return themeValue
}

const getColor = (colorID) => {
    let primColor = 'red'
    let secColor = 'green'
    let tertColor = '#eee'
    let bgColor = 'red'

    if (colorID) {
        if (colorID === 'clr1') {
            primColor = '#73b253'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr2') {
            primColor = '#009fef'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr3') {
            primColor = '#006fb3'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr4') {
            primColor = '#58445e'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr5') {
            primColor = '#e0b04a'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr6') {
            primColor = '#b4b028'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr7') {
            primColor = '#aa3f2e'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr8') {
            primColor = '#D45804'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr9') {
            primColor = '#333333'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr10') {
            primColor = '#ffa43c'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr11') {
            primColor = '#00638f'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr12') {
            primColor = '#3db9ea'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr13') {
            primColor = '#ef73e5'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr14') {
            primColor = '#25afb1'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr15') {
            primColor = '#dc1d66'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        } else if (colorID === 'clr16') {
            primColor = '#693465'
            secColor = 'green'
            tertColor = '#eee'
            bgColor = 'red'
        }
    }
    return {
        primColor,
        secColor,
        tertColor,
        bgColor,
    }
}

const getLogo = (logoValue) => {
    let logoPath = process.env.REACT_APP_AUCTION_IMAGE_URL
    if (logoValue) {
        return logoPath + logoValue
    }
    return process.env.REACT_APP_BASE_URL + 'images/no_imf.png'
}
const getFont = (fontID) => {
    let fontValue = 'Open Sans'
    if (fontID) {
        if (fontID === 'font1') {
            fontValue = 'Lato'
        } else if (fontID === 'font2') {
            fontValue = 'Roboto Condensed'
        } else if (fontID === 'font3') {
            fontValue = 'Cuprum'
        } else if (fontID === 'font4') {
            fontValue = 'Merriweather'
        } else if (fontID === 'font5') {
            fontValue = 'Dancing Script'
        } else if (fontID === 'font6') {
            fontValue = 'Pontano Sans'
        } else if (fontID === 'font7') {
            fontValue = 'Arvo'
        } else if (fontID === 'font8') {
            fontValue = 'Lobster'
        } else if (fontID === 'font9') {
            fontValue = 'Lobster Two'
        } else if (fontID === 'font10') {
            fontValue = 'Quattrocento'
        } else if (fontID === 'font11') {
            fontValue = 'Raleway'
        } else if (fontID === 'font12') {
            fontValue = 'Playfair Display'
        } else if (fontID === 'font13') {
            fontValue = 'Cabin'
        } else if (fontID === 'font14') {
            fontValue = 'Source Sans Pro'
        } else if (fontID === 'font15') {
            fontValue = 'Montserrat'
        } else if (fontID === 'font16') {
            fontValue = 'Roboto Slab'
        } else if (fontID === 'font17') {
            fontValue = 'Orbitron'
        } else if (fontID === 'font18') {
            fontValue = 'Poppins'
        } else if (fontID === 'font19') {
            fontValue = 'Barlow'
        } else if (fontID === 'font20') {
            fontValue = 'Roboto'
        } else if (fontID === 'font21') {
            fontValue = 'Open Sans'
        }
    }
    return fontValue
}

global.searchCategories = []
global.searchSubCategories = []
global.searchConditions = []
global.storeConfigration = {}
global.multipleBanners = []

let allSite = [
    { site: 'localdeals.bid', storeID: 23 },
    { site: 'auctions.asminc360.com', storeID: 58 },
    { site: 'fullbasketdeals.com', storeID: 32 },
    { site: 'localhost:3000', storeID: 2 },
    { site: 'mobiusauctions.com', storeID: 45 },
    { site: 'zippbid.com', storeID: 67 },
    { site: 'blowfish.shop', storeID: 83 },
    { site: 'carolinaauctioneer.com', storeID: 109 },
    { site: 'cqhlive.ecommerce.auction', storeID: 136 },
    { site: 'auctions.hurstcfa.com', storeID: 124 },
    { site: 'www.dunncoonlineauctions.com', storeID: 148 },
    { site: 'oneminuteauction.com', storeID: 122 },
    { site: 'pallethawkny.com', storeID: 144 },
    { site: 'artauction.io', storeID: 146 },
    { site: 'pinnacleauctions.com', storeID: 156 },
    { site: 'auction.topspoke.com', storeID: 171 },
    { site: 'myauctionhouse.com', storeID: 163 },
    { site: 'auction.lotofcards.com', storeID: 202 },
    { site: 'www.hawaiiauctionservices.com', storeID: 211 },
    { site: 'bomauctions.com', storeID: 216 },
    { site: 'bidnaa.com', storeID: 218 },
    { site: 'estatetocleanslate-prod.ecommerce.auction', storeID: 219 },
    { site: 'westmoreauctions.com', storeID: 262 },
    { site: 'muchforless.ca', storeID: 273 },
    { site: 'prelive.auctionwarehouse.ca', storeID: 273 },
    { site: 'ownaslot.com', storeID: 323 },
    { site: 'auctions.mysilverattic.com', storeID: 368 },
    { site: 'bidonwilmington.com', storeID: 360 },
    { site: 'auction.mcmanusauctions.com', storeID: 408 },
    { site: 'primebidsauction.com', storeID: 413 },
    { site: 'thatisadeal.com', storeID: 426 },
    { site: 'auction.musicollectibles.com', storeID: 427 },
    { site: 'globalgi.org', storeID: 416 },
    { site: 'donation.globalgi.org', storeID: 416 },
]

export default function Routes() {
    const authContext = useContext(AuthContext)

    const cartContext = useContext(CartContext)

    const { getAllStripeCards, getSkyflowCards } = useContext(StripeCardContext)
    const { getStripeCard } = useContext(DirectStripeContext)

    const { user, loadUser, updateMessageCount, userCount, updateUnpaidCount } = authContext
    const {
        list_buyers,
        updateUserMessageCount,
        listAllBuyers,
        buyer_allproducts,
        updateVideoCount,
        getAllBuyerProducts,
        globalChatUser,
    } = useContext(buyerContext)
    const { getUserCart } = cartContext
    const { setState } = useContext(ProductContext)
    const { i18n } = useTranslation()
    const [theme, setTheme] = useState(null)
    const [storeID, setStoreID] = useState(null)
    const [subPath, setSubPath] = useState('')
    const [liveSite, setLiveSite] = useState(false)
    const [intercomBoot, setIntercomBoot] = useState(false)
    const [intercomId, setIntercomId] = useState('')
    const [userIn, setUserIn] = useState(false)
    const messageRef = useRef(userCount)
    const globalChatUserRef = useRef(globalChatUser)
    const listBuyersRef = useRef(list_buyers)
    const buyerAllProductsRef = useRef(buyer_allproducts)
    const userRef = useRef(user)
    useEffect(() => {
        messageRef.current = userCount
        globalChatUserRef.current = globalChatUser
        listBuyersRef.current = list_buyers
        buyerAllProductsRef.current = buyer_allproducts
        userRef.current = user
    })
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (subPath) {
            setStoreID(parseInt(subPath, 10))
        }
    }, [subPath])

    useEffect(() => {
        let windowURL = window.location.href.split('/')
        let url = windowURL[2]
        if (
            url === 'preview.auction.io' ||
            url === 'auction_preview.ecommerce.auction' ||
            url === 'localhost:3000' ||
            url === '192.168.150.222:3000' ||
            url === '192.168.20.114:3000' ||
            url === '192.168.20.114:3001' ||
            url === 'auctioniotest1.ecommerce.auction' ||
            url === '192.168.150.138:3001' ||
            url === '192.168.150.138:3000'
        ) {
            setSubPath(getBasename(window.location.pathname))
            setLiveSite(false)
        } else {
            let obj = allSite.find((o) => o.site === url)
            if (obj) {
                setStoreID(parseInt(obj.storeID, 10))
                setLiveSite(true)
            }
        }
    }, [])

    // Maintenance Mode Integration
    const maintenanceMode = async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}plugin/maintenance/site/maintenance/checkMaintenance`,
                { site_id: global.storeDetails.site_id },
            )
            if (res.data.status === 'success') {
                if (res.data.data.message === 'maintenance-on') {
                    if (!window.location.pathname.includes('maintenance')) {
                        window.location.href = liveSite
                            ? '/maintenance'
                            : getBasename(window.location.pathname)
                            ? `/${getBasename(window.location.pathname)}/maintenance`
                            : '/maintenance'
                    }
                }
                if (res.data.data.message === 'maintenance-off') {
                    if (window.location.pathname.includes('maintenance')) {
                        window.location.href = liveSite
                            ? '/'
                            : getBasename(window.location.pathname)
                            ? `/${getBasename(window.location.pathname)}/`
                            : '/'
                    }
                }
            }
        } catch (error) {
            //console.log(error)
        }
    }
    useEffect(() => {
        socketAuction.on('pluginMode', (data) => {
            if (global.storeDetails.site_id === data.site_id) {
                if (data.type === 'maintenance') {
                    if (parseInt(data.enable)) {
                        if (!window.location.pathname.includes('maintenance')) {
                            window.location.href = liveSite
                                ? '/maintenance'
                                : getBasename(window.location.pathname)
                                ? `/${getBasename(window.location.pathname)}/maintenance`
                                : '/maintenance'
                        }
                    } else {
                        if (window.location.pathname.includes('maintenance')) {
                            window.location.href = liveSite
                                ? '/'
                                : getBasename(window.location.pathname)
                                ? `/${getBasename(window.location.pathname)}/`
                                : '/'
                        }
                    }
                }
            }
            //console.log('Plugin Data=>', data)
        })
    }, [])
    useEffect(() => {
        if (storeID) {
            document.body.classList.add(theme)
            if (localStorage.token) {
                loadUser()
                getUserCart()
            }
            maintenanceMode()
        }
        if (global?.storeConfigration?.bodyClass?.value) {
            document.body.classList.add(global.storeConfigration.bodyClass.value)
        }
    }, [theme])
    // useEffect(() => {
    //     if (user) {
    //         getUserCart()
    //     }
    // }, [user])
    useEffect(() => {
        if (user) {
            if (global.storeConfigration?.skyflow_integration?.value == 1) {
                getSkyflowCards()
            } else {
                if (
                    user.card_paymentid &&
                    global.storeConfigration?.stripe_integration?.value != 1
                ) {
                    getAllStripeCards({
                        account_id: process.env.REACT_APP_AUCTIONPAYID,
                        customer_id: user.card_paymentid,
                        object: 'card',
                    })
                }
                if (
                    user.card_paymentid &&
                    global.storeConfigration?.stripe_integration?.value == 1
                ) {
                    getStripeCard({ user_id: user?.id, site_id: global.storeDetails.site_id })
                }
            }
            listAllBuyers({
                limit_value: 0,
                search_key: ' ',
                user_role: user?.role == 1 ? '' : 1,
            })
        }
    }, [user, global.storeConfigration])
    useEffect(() => {
        if (window.location.pathname.includes('liveLots')) {
            //console.log('Im in')
            setUserIn(true)
            socket.emit('userWatch', 'userConnected')
        } else {
            if (userIn) {
                //console.log('Im out')
                setUserIn(false)
                socket.emit('userWatch', 'userDisconnected')
            }
        }
    }, [window.location.pathname])
    useEffect(() => {
        if (storeID) {
            let body = {
                storeID,
            }
            axios
                .post(`${process.env.REACT_APP_AUCTION_URL}getstores`, body)
                .then(function (response) {
                    if (response.data.status === 'success') {
                        global.allCategories = response?.data.response?.all_cat?.map((buscat) => {
                            let busCategoryChanged = {}
                            busCategoryChanged.description = buscat.name
                            busCategoryChanged.name = buscat.description
                            busCategoryChanged.value = buscat.id
                            busCategoryChanged.id = buscat.id
                            busCategoryChanged.level_id = buscat.parent_id
                            busCategoryChanged.level = buscat.parent_id ? 1 : 0
                            busCategoryChanged.active = 1
                            return busCategoryChanged
                        })

                        global.searchCategories = response?.data.response?.parent_cat?.map(
                            (buscat) => {
                                let busCategoryChanged = {}
                                busCategoryChanged.description = buscat.name
                                busCategoryChanged.description_without_count = buscat.name
                                busCategoryChanged.name = buscat.description
                                busCategoryChanged.value = buscat.id
                                busCategoryChanged.id = buscat.id
                                busCategoryChanged.level_id = buscat.parent_id
                                busCategoryChanged.active = 1
                                busCategoryChanged.image = buscat.image ? buscat.image : 'noimg'
                                return busCategoryChanged
                            },
                        )
                        global.searchSubCategories = response?.data.response?.sub_cat?.map(
                            (buscat) => {
                                let busCategoryChanged = {}
                                busCategoryChanged.description = buscat.name
                                busCategoryChanged.description_without_count = buscat.name
                                busCategoryChanged.name = buscat.description
                                busCategoryChanged.value = buscat.id
                                busCategoryChanged.id = buscat.id
                                busCategoryChanged.level_id = buscat.parent_id
                                busCategoryChanged.active = 1
                                busCategoryChanged.image = buscat.image ? buscat.image : 'noimg'
                                return busCategoryChanged
                            },
                        )

                        global.multipleBanners = response?.data.response?.banner_image_arr
                            ?.map((image) => {
                                let imageArr = []
                                imageArr.push(image.image_name)
                                return imageArr
                            })
                            .flat()
                        global.multipleBannersFields = response?.data.response?.banner_image_arr
                        global.ads_data = response?.data.response?.ads_data
                        global.currencies = response?.data.response?.currencies
                        global.language = response?.data.response?.language
                        let storeDetails = response.data.response.results
                        let storeSeller =
                            response.data.response.seller_details &&
                            response.data.response.seller_details.length > 0
                                ? response.data.response.seller_details[0]
                                : ''
                        let colorValue = getColor(storeDetails.theme_css)
                        let fontValue = getFont(storeDetails.theme_font)
                        storeDetails.logoValue = getLogo(storeDetails.logo)
                        storeDetails.footerLogoValue = getLogo(storeDetails.footer_logo)
                        storeDetails.bannerValue = getLogo(storeDetails.banner)
                        storeDetails.fontValue = fontValue
                        storeDetails.colorValue = colorValue
                        storeDetails.text = JSON.parse(
                            storeDetails.text_carousel?.includes('[')
                                ? storeDetails.text_carousel
                                : '[]',
                        )
                        if (response.data.response.nav_menu) {
                            var nav_menu = response.data.response.nav_menu
                        } else {
                            var nav_menu = []
                        }
                        if (nav_menu.length > 0) {
                            storeDetails.header_menu = nav_menu.filter(
                                (val) => val.menu_type == 'header',
                            )
                            storeDetails.footer_menu = nav_menu.filter(
                                (val) => val.menu_type == 'footer',
                            )
                        } else {
                            storeDetails.header_menu = []
                            storeDetails.footer_menu = []
                        }

                        global.storeDetails = storeDetails
                        global.storeSeller = storeSeller
                        let storeConfigration = {}
                        for (const ele of response.data.response.store_configuration) {
                            let cpyStoreConfig = { ...storeConfigration }
                            cpyStoreConfig[`${ele.variable}`] = ele

                            storeConfigration = cpyStoreConfig

                            if (ele.variable === 'intercom' && !!Number(ele.value)) {
                                setIntercomBoot(true)
                            }
                        }
                        global.storeConfigration = storeConfigration
                        if (storeConfigration?.global_locations?.value) {
                            let globalLocations =
                                storeConfigration?.global_locations?.value?.split(',') || []
                            if (storeConfigration?.global_location_filter?.value == 2) {
                                globalLocations =
                                    storeConfigration?.global_locations?.value?.split('|') || []
                                if (localStorage.getItem('globalLocation')) {
                                    if (!localStorage.getItem('globalLocation')?.includes(',')) {
                                        localStorage.setItem('globalLocation', globalLocations[0])
                                    }
                                }
                            }
                            let disableBuynowLocations =
                                storeConfigration?.disable_buynow_locations?.value?.split(',') || []
                            if (!localStorage.getItem('globalLocation')) {
                                localStorage.setItem('globalLocation', globalLocations[0])
                            }
                            setState({
                                globalLocations,
                                globalLocation: localStorage.getItem('globalLocation')
                                    ? localStorage.getItem('globalLocation')
                                    : globalLocations[0],
                                globalLocationFilter:
                                    storeConfigration?.global_location_filter?.value || 0,
                                disableBuynowLocations,
                            })
                        }

                        let pluginConfigration = {}
                        if (response?.data?.response?.active_plugin) {
                            for (const ele of response.data.response.active_plugin) {
                                let cpyStoreConfig = { ...pluginConfigration }
                                cpyStoreConfig[`${ele.plugin_name}`] = ele

                                pluginConfigration = cpyStoreConfig
                            }
                        }
                        global.pluginConfiguration = pluginConfigration
                        global.homedepot_key = response?.data?.response?.homedepot_key
                        global.asinscope_key = response?.data?.response?.asinscope_key
                        global.condition_data = response?.data?.response?.condition_data
                        if (
                            global?.pluginConfiguration?.language_manager?.enable != 1 ||
                            storeDetails.theme != 11
                        ) {
                            english_text.map((val) => {
                                i18n.addResourceBundle(
                                    'for_english',
                                    'translations',
                                    val,
                                    true,
                                    true,
                                )
                            })
                        } else {
                            localStorage.removeItem('language_loaded')
                        }

                        axios.defaults.headers.common['site_id'] = storeDetails.site_id
                        setTheme(getTheme(storeDetails.theme))
                        // setTheme('LiquidationPallet')
                        // setTheme('liquidationTwo')

                        const theme = {
                            '--primColor': storeDetails.theme_css,
                            '--secColor': storeDetails.secondary_theme,
                            '--tertColor': colorValue.tertColor,
                            '--bgColor': colorValue.bgColor,
                            '--primFont': fontValue,
                            '--headerColor': storeDetails.header_theme,
                            '--headerFontColor': storeDetails.header_font_color,
                            '--footerColor': storeDetails.footer_theme,
                            '--footerFontColor': storeDetails.footer_font_color,
                            '--bannerTextColor': storeDetails.banner_text_color,
                            '--titleFont': '',
                            '--btnBorderRadius': '5',
                            '--siteName': 'Auction.io store',
                            '--logo': '',
                        }

                        // document.title = storeDetails.name
                        // document.getElementById('favicon').href = storeDetails.logoValue
                        Object.keys(theme).map((key) => {
                            const value = theme[key]
                            document.documentElement.style.setProperty(key, value)
                        })

                        const manifestURL = `/public/manifest/${storeDetails.id}.json`
                        document
                            .querySelector('#manifestPlaceholder')
                            .setAttribute('href', manifestURL)
                    }
                })
                .catch(function (error) {
                    //console.log(error)
                })
        }
    }, [storeID])
    useEffect(() => {
        if (intercomBoot && global.storeConfigration?.intercom_code.value) {
            setIntercomId(global.storeConfigration?.intercom_code.value)
        }
    }, [intercomBoot, global])

    useEffect(() => {
        if (user) {
            socket.on('insertMessages_count', (data) => {
                console.log(
                    data,
                    userCount,
                    '+++++',
                    messageRef.current,
                    listBuyersRef.current,
                    data.from_id != globalChatUserRef.current?.id,
                )
                if (data.to_id == user?.id) {
                    if (!data.project_id && data.from_id != globalChatUserRef.current?.id) {
                        updateMessageCount(
                            parseInt(messageRef.current?.messageCount) + 1,
                            parseInt(messageRef.current?.messageCountProjects),
                        )
                    }
                    if (data.project_id) {
                        updateMessageCount(
                            parseInt(messageRef.current?.messageCount),
                            parseInt(messageRef.current?.messageCountProjects) + 1,
                        )
                    }
                }
                if (buyerAllProductsRef.current?.results?.length) {
                    buyerAllProductsRef.current?.results.map((val) => {
                        if (
                            userRef.current?.id == data.to_id &&
                            data.project_id == val.common_invoice
                        ) {
                            val.msg_count += 1
                        }
                    })
                    updateVideoCount(buyerAllProductsRef.current)
                }
                if (
                    listBuyersRef.current?.length &&
                    data.from_id != globalChatUserRef.current?.id
                ) {
                    listBuyersRef.current.map((val) => {
                        if (val.id == data.from_id && !data.project_id) {
                            val.msg_count += 1
                        }
                    })
                    updateUserMessageCount(listBuyersRef.current)
                }
            })
            socket.on('winning_indication', (data) => {
                console.log(data, 'winning_indication', messageRef.current?.itemunPaidCount)
                if (data.usr == user?.id) {
                    updateUnpaidCount(parseInt(messageRef.current?.itemunPaidCount) + 1)
                }
            })
        }

        return () => {
            socket.off('insertMessages_count', (data) => {
                return data
            })
            socket.off('winning_indication', (data) => {
                return data
            })
        }
    }, [user])
    useEffect(() => {
        if (socket) {
            socket.on('pro_mov_unsold', (data) => {
                if (localStorage.getItem('token')) {
                    var user_id = jwtDecode(localStorage.getItem('token'))

                    if (parseInt(data.user_id) == parseInt(user_id?.id)) {
                        loadUser()
                        if (window.location.href.includes('mybids?s=1')) {
                            getAllBuyerProducts({
                                status: 'bidwon',
                                sh_limit: 100,
                                page: 1,
                                order: 1,
                                ftpImage: true,
                                auction_only_io: global.pluginConfiguration?.make_an_offer?.enable
                                    ? 1
                                    : undefined,
                                search: '',
                                get_msg_count:
                                    global?.storeConfigration?.unique_slot4?.value == 1 ? 1 : 0,
                            })
                        }
                        enqueueSnackbar(
                            `Seller moved invoice no ${data.common_invoice} to unsold items.`,
                            {
                                variant: 'error',
                                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                                preventDuplicate: true,
                            },
                        )
                    }
                }
            })

            return () => {
                socket.off('pro_mov_unsold', (data) => {
                    return data
                })
            }
        }
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on('auctionio_user_status', (data) => {
                if (localStorage.getItem('token')) {
                    var user_id = jwtDecode(localStorage.getItem('token'))
                    if (
                        parseInt(data.user_id) == parseInt(user_id?.id) &&
                        (data.status == 'deactivate' ||
                            data.status == 'unverified' ||
                            (data.status == 'suspended' &&
                                global.storeConfigration?.suspended_user_access?.value != 1))
                    ) {
                        enqueueSnackbar(
                            `Your account has been ${
                                data.status == 'deactivate' ? 'deactivated' : data.status
                            }. Please contact admin.`,
                            {
                                variant: 'error',
                                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                                preventDuplicate: true,
                            },
                        )
                        localStorage.removeItem('token')
                        window.location.href = window.location.href.includes(
                            global?.storeDetails?.id,
                        )
                            ? `${window.location.origin}/${global?.storeDetails?.id}/login`
                            : `${window.location.origin}/login`
                    } else if (
                        parseInt(data.user_id) == parseInt(user_id?.id) &&
                        (data.status == 'suspended' || data.status == 'active')
                    ) {
                        window.location.reload()
                    }
                }
            })
            return () => {
                socket.off('auctionio_user_status', (data) => {
                    if (localStorage.getItem('token')) {
                        var user_id = jwtDecode(localStorage.getItem('token'))
                        if (
                            parseInt(data.user_id) == parseInt(user_id?.id) &&
                            (data.status == 'deactivate' ||
                                data.status == 'unverified' ||
                                (data.status == 'suspended' &&
                                    global.storeConfigration?.suspended_user_access?.value != 1))
                        ) {
                            enqueueSnackbar(
                                `Your account has been ${
                                    data.status == 'deactivate' ? 'deactivated' : data.status
                                }. Please contact admin.`,
                                {
                                    variant: 'error',
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                                    preventDuplicate: true,
                                },
                            )
                            localStorage.removeItem('token')
                            window.location.href = window.location.href.includes(
                                global?.storeDetails?.id,
                            )
                                ? `${window.location.origin}/${global?.storeDetails?.id}/login`
                                : `${window.location.origin}/login`
                        } else if (
                            parseInt(data.user_id) == parseInt(user_id?.id) &&
                            (data.status == 'suspended' || data.status == 'active')
                        ) {
                            window.location.reload()
                        }
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on('bidder_suspension', (data) => {
                if (localStorage.getItem('token')) {
                    var user_id = jwtDecode(localStorage.getItem('token'))

                    var check_suspend = 0
                    var arr_user = data.user_id.split(',')

                    arr_user.map((val) => {
                        if (parseInt(val) == parseInt(user_id?.id)) {
                            check_suspend = 1
                        }
                    })
                    if (check_suspend == 1) {
                        window.location.reload()
                    }
                }
            })

            return () => {
                socket.off('bidder_suspension', (data) => {
                    if (localStorage.getItem('token')) {
                        var user_id = jwtDecode(localStorage.getItem('token'))

                        var check_suspend = 0
                        var arr_user = data.user_id.split(',')
                        arr_user.map((val) => {
                            if (parseInt(val) == parseInt(user_id?.id)) {
                                check_suspend = 1
                            }
                        })
                        if (check_suspend == 1) {
                            window.location.reload()
                        }
                    }
                })
            }
        }
    }, [])

    return (
        <>
            <SEO
                title={
                    global?.storeConfigration?.meta_title?.value != '' &&
                    global?.storeConfigration?.meta_title?.value != undefined
                        ? global?.storeConfigration?.meta_title?.value
                        : global?.storeDetails?.name
                }
                description={
                    global?.storeConfigration?.meta_description?.value != '' &&
                    global?.storeConfigration?.meta_description?.value != undefined
                        ? global?.storeConfigration?.meta_description?.value
                        : global?.storeDetails?.description
                }
                appleTouchIcon={global?.storeDetails?.logoValue}
                favIcon={
                    global?.storeDetails?.favicon
                        ? process.env.REACT_APP_AUCTION_IMAGE_URL +
                          '/' +
                          global?.storeDetails?.favicon
                        : global?.storeDetails?.logoValue
                }
                keywords={
                    global?.storeConfigration?.meta_keywords?.value != '' &&
                    global?.storeConfigration?.meta_keywords?.value != undefined
                        ? global?.storeConfigration?.meta_keywords?.value
                        : ''
                }
            />

            {global.storeConfigration?.google_analytics?.value ? (
                <Helmet>
                    <script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${global.storeConfigration?.google_analytics?.value}`}
                    ></script>
                    <script>
                        {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date()); 
                    gtag('config', '${global.storeConfigration?.google_analytics?.value}');`}
                    </script>
                </Helmet>
            ) : null}
            {global?.storeConfigration?.fb_meta_pixel_id?.value ? (
                <Helmet>
                    <script>
                        {`!(function (f, b, e, v, n, t, s) {
                if (f.fbq) return
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                if (!f._fbq) f._fbq = n
                n.push = n
                n.loaded = !0
                n.version = '2.0'
                n.queue = []
                t = b.createElement(e)
                t.async = !0
                t.src = v
                s = b.getElementsByTagName(e)[0]
                s.parentNode.insertBefore(t, s)
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
            fbq('init', '${global?.storeConfigration?.fb_meta_pixel_id?.value}')
            fbq('track', 'PageView')`}
                    </script>
                </Helmet>
            ) : null}

            {global.storeConfigration?.google_tag?.value ? (
                <Helmet>
                    <script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${global.storeConfigration?.google_tag?.value}`}
                    ></script>
                    <script>
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${global.storeConfigration?.google_tag?.value}');`}
                    </script>
                </Helmet>
            ) : null}
            {global.storeConfigration?.google_verification_content?.value ? (
                <Helmet>
                    <meta
                        name="google-site-verification"
                        content={global.storeConfigration?.google_verification_content?.value}
                    />
                </Helmet>
            ) : (
                ''
            )}

            {/* {global.storeConfigration?.google_tag?.value ? (
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${global.storeConfigration?.google_tag?.value}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
            ) : null} */}
            <BrowserRouter
                basename={
                    liveSite
                        ? null
                        : getBasename(window.location.pathname)
                        ? `/${getBasename(window.location.pathname)}`
                        : null
                }
            >
                <Switch>
                    {theme ? (
                        <>
                            <IntercomProvider appId={intercomId} autoBoot={intercomBoot}>
                                {theme === 'Auctioneer' ? (
                                    <AuctioneerRoutes />
                                ) : theme === 'Liquidation' ? (
                                    <LiquidationRoutes />
                                ) : theme === 'MultiSeller' ? (
                                    <MultiSellerRoutes />
                                ) : theme === 'HeavyEquipment' ? (
                                    <HeavyEquipmentRoutes />
                                ) : theme === 'Fundraiser' ? (
                                    <FundraiserRoutes />
                                ) : theme === 'CarAuction' ? (
                                    <CarAuctionRoutes />
                                ) : theme === 'MultiSeller_two' ? (
                                    <MultiSeller_two_Routes />
                                ) : theme === 'FoodIndustry' ? (
                                    <FoodIndustry_Routes />
                                ) : theme === 'MultiAuction' ? (
                                    <MultiAuction_Routes />
                                ) : theme === 'LiveStock' ? (
                                    <LiveStock_Routes />
                                ) : theme === 'ArtAuction' ? (
                                    <ArtAuction_Routes />
                                ) : theme === 'LiquidationPallet' ? (
                                    <LiquidationPalletRoutes />
                                ) : theme === 'liquidationTwo' ? (
                                    <LiquidationTwoRoutes />
                                ) : theme === 'Collectibles' ? (
                                    <CollectiblesRoutes />
                                ) : theme === 'Marine' ? (
                                    <MarineRoutes />
                                ) : theme === 'LiquidationThree' ? (
                                    <LiquidationThreeRoutes />
                                ) : null}
                            </IntercomProvider>
                        </>
                    ) : null}
                </Switch>
            </BrowserRouter>
        </>
    )
}
