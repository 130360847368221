import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import {
    make_an_offer_update_socket_multiple,
    messageHandler,
    reverse_auction_item,
} from '../../../Product/common/socketHandler'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Link } from 'react-router-dom'
import Donation from '../../../Component/Donation'
import Layout from '../Layout'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { mapData } from '../../../Product/common/components'
import { useFormik } from 'formik'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const [private_auction, setPrivateauction] = useState(0)
    const [parentcategories, setParentCategories] = useState([])
    const [childcategories, setChildcategories] = useState([])
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
        auc_cat_value,
        getauctioncat_subcat,
    } = productContext

    let history = useHistory()
    const [auctionData, setAuctionData] = useState({})
    const [minMaxDate, setMinMaxDate] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [upcomming, setUpcomming] = useState(false)
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    let auctionid = props.match.params.id
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
        auction_io: 1,
        lot_sequence_order:
            global?.storeConfigration?.lot_sequence_order?.value == 1 ? 1 : undefined,
    })

    const [auctionLoading, setAuctionLoading] = useState(true)
    const [productLoading, setProductLoading] = useState(true)
    const [isRegistered, setIsRegistered] = useState(0)
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid: props.match.params.id }, 'auctionView')
        setAuctionLoading(true)
    }, [props.match.params.id, isAuthenticated])

    useEffect(() => {
        getLotList(subLotSearch, 'auctionSubView')
        if (global?.storeConfigration?.auction_cat_fill?.value) {
            getauctioncat_subcat(
                { lotof: auctionid, site_id: global?.storeDetails?.site_id },
                'auction_sub',
            )
        }
        setProductLoading(true)
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionSubView') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setProductLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setMinMaxDate(edit_auction.min_max_date_closed)
                setIsRegistered(edit_auction.is_registered)
            }
            setTimeout(() => {
                setAuctionLoading(false)
            }, 1500)
        }
    }, [edit_auction])

    const handlePage = (pageNumber) => {
        document.getElementById('sublot').scrollIntoView()
        setSubLotSearch({ ...subLotSearch, page: pageNumber })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket_multiple(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const multiauction_handler = (message, type) => {
        var index = viewProductRef.current.findIndex(
            (s) => s.id === parseInt(message.id || message.project_id, 10),
        )
        if (index !== -1) {
            if (parseInt(viewProductRef.current[index].content_head5) == 0) {
                messageHandler(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSubLotData,
                    type,
                )
            } else {
                if (message.type == 'reverse_auction' && message.status == 'success') {
                    reverse_auction_item(
                        message,
                        viewProductRef.current,
                        userRef.current,
                        setAlert,
                        setSubLotData,
                        'multiple',
                    )
                }
            }
        }
    }

    useEffect(() => {
        if (auctionData?.dynamic_fields?.includes('[{')) {
            var data_obj = {}
            JSON.parse(auctionData.dynamic_fields).map((data) => {
                if (data.name == 'private_auction') {
                    data_obj.private_auction = 0
                    if (data.values.length > 0) {
                        if (data.values.filter((v) => v.selected == 'true').length > 0) {
                            data_obj.private_auction = data.values.filter(
                                (v) => v.selected == 'true',
                            )[0].value
                        }
                    }
                }
            })
            setPrivateauction(parseInt(data_obj.private_auction))
        } else {
            setPrivateauction(0)
        }
    }, [auctionData])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            data.id = data.pid
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })

        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })

            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            category: [],
            subcategory: [],
        },
    })

    var filtervalues = [
        {
            label: 'Select Category',
            placeholder: 'Select Category',
            type: 'multiselect',
            class: `col-md-3`,
            options: parentcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: data.name,
                }
            }),
            defaultOptionValue: 'Select Category',
            name: 'category',
            formik: formik,
        },
        {
            label: 'Select SubCategory',
            placeholder: 'Select SubCategory',
            type: 'multiselect',
            no_item: 'No Subcategories found',
            class: `col-md-3`,
            options: childcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: data.name,
                }
            }),
            defaultOptionValue: 'Select SubCategory',
            name: 'subcategory',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (formik.values.category.length > 0 || formik.values.subcategory.length > 0) {
            getLotList(
                {
                    ...subLotSearch,
                    ...{
                        cat_id_multiple_io: formik.values.category,
                        sub_cat_id_multiple_io: formik.values.subcategory,
                    },
                },
                'auctionSubView',
            )
        }
    }, [formik.values.category, formik.values.subcategory])

    useEffect(() => {
        if (auc_cat_value?.auc_cat.length > 0) {
            setParentCategories(auc_cat_value.auc_cat)
        } else {
            setParentCategories([])
        }
        if (auc_cat_value?.auc_sub_cat.length > 0) {
            setChildcategories(auc_cat_value.auc_sub_cat)
        } else {
            setChildcategories([])
        }
    }, [auc_cat_value])

    return (
        <Layout props={props}>
            <UserActivity page="auction_view_page" />
            <div className="customContainer pt-3 auctionCnt">
                {auctionLoading ? (
                    <Loaders name="product_view" isLoading={auctionLoading} loop={1} />
                ) : (
                    <>
                        <div className="co-breadcrumb">
                            <Link to="/auctions">Search</Link> / {auctionData?.title}
                        </div>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            minMaxDateClosed={minMaxDate}
                            setUpcomming={setUpcomming}
                            is_registered={isRegistered}
                            setIsRegistered={setIsRegistered}
                            auction_id={props.match.params.id}
                        />
                        <div ref={myRef}></div>
                    </>
                )}
                {productLoading ? (
                    <Loaders name="table" isLoading={productLoading} loop={1} />
                ) : (
                    <>
                        {global?.storeConfigration?.auction_cat_fill?.value ? (
                            <div className="row mt-5">{mapData(filtervalues)}</div>
                        ) : (
                            ''
                        )}

                        <SubLots
                            selectedPostion={handleClick}
                            handleClick={handleClick}
                            title={auctionData.title}
                            auctionid={auctionid}
                            lotData={subLotData}
                            subLotDataCount={subLotDataCount}
                            subLotSearch={subLotSearch}
                            setSubLotSearch={setSubLotSearch}
                            showviews={
                                global?.storeConfigration?.needgridview?.value
                                    ? parseInt(global.storeConfigration.needgridview.value)
                                    : 0
                            }
                            setSubLotData={setSubLotData}
                            audio={audio}
                            upcomming={upcomming}
                            private_auction={private_auction}
                            is_registered={isRegistered}
                        />
                        <SlidePanel
                            type="right"
                            selectedLot={selectedProduct}
                            handleClose={handleClose}
                            from={''}
                            getAllProducts={() => false}
                            audio={audio}
                            enableZoomOnHover={true}
                            private_auction={private_auction}
                            is_registered={isRegistered}
                        />
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
