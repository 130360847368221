import React, { useState, useEffect } from 'react'
import { socket } from './socket'
import moment from 'moment'
// import AuthContext from '../context/auth/authContext';
let serverTime = new Date()

const getTimeRemaining = (t) => {
    var seconds = ('0' + Math.floor((t / 1000) % 60)).slice(-2)
    var minutes = ('0' + Math.floor((t / 1000 / 60) % 60)).slice(-2)
    var hours = ('0' + Math.floor((t / (1000 * 60 * 60)) % 24)).slice(-2)
    var days = 0
    if (Math.floor(t / (1000 * 60 * 60 * 24)).toString().length >= 2) {
        days = Math.floor(t / (1000 * 60 * 60 * 24))
    } else {
        days = ('0' + Math.floor(t / (1000 * 60 * 60 * 24))).slice(-2)
    }
    return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    }
}

const Timer = (props) => {
    // const authContext = useContext(AuthContext);
    // const { user } = authContext;
    const [timer, setTimer] = useState({
        timervalid: false,
        timer_id: 0,
        days: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
        redflash: false,
    })

    var sec_to_end = parseFloat(
        global.storeConfigration?.red_flash_value?.value
            ? global.storeConfigration.red_flash_value.value
            : -1,
    )

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    serverTime = new Date(data.dTime)
                }
            })
        }
    }, [socket])

    useEffect(() => {
        if (
            props.date_closed &&
            moment(props.date_closed).isValid() &&
            props.date_added &&
            moment(props.date_added).isValid()
        ) {
            const interval = setInterval(() => {
                let startDate = new Date(serverTime)
                let endDate = new Date(props.date_closed)
                let addDate = new Date(props.date_added)
                let timerTime = endDate.getTime() - startDate.getTime()
                let future = false
                let milliSeconds = 0
                // if (parseInt(user.role) !== 3) {
                //   milliSeconds = 1 * 3600000;
                // } else {
                // }
                let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
                let newaddDate = new Date(incrementedTime)
                if (newaddDate > startDate) {
                    timerTime = incrementedTime - startDate.getTime()
                    future = true
                }
                var t = getTimeRemaining(timerTime)

                if (t.total <= 0) {
                    if (t.future) {
                    } else {
                        setTimer({
                            ...timer,
                            timer_id: interval,
                            timervalid: false,
                        })
                        clearInterval(interval)
                    }
                } else {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: true,
                        days: t.days,
                        seconds: t.seconds,
                        minutes: t.minutes,
                        hours: t.hours,
                        future: future,
                    })
                }
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        } else if (props.date_closed && moment(props.date_closed).isValid()) {
            const interval = setInterval(() => {
                var startDate = new Date(serverTime)
                var endDate = new Date(props.date_closed)
                var timerTime = endDate.getTime() - startDate.getTime()
                var t = getTimeRemaining(timerTime)
                if (t.total <= 0) {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: false,
                    })
                    clearInterval(interval)
                } else {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: true,
                        days: t.days,
                        seconds: t.seconds,
                        minutes: t.minutes,
                        hours: t.hours,
                    })
                }
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [props.date_closed])

    useEffect(() => {
        if (global.storeConfigration?.bidding_sound?.value == 1) {
            if (timer.timer_id != 0 && timer.timervalid) {
                if (
                    timer.days == '00' &&
                    timer.hours == '00' &&
                    timer.minutes == '00' &&
                    timer.seconds == '00'
                ) {
                    if (props.audio != '' && props.audio != undefined) {
                        props.audio.play()
                    }
                }
            }
        }

        if (global.pluginConfiguration?.make_an_offer?.enable) {
            if (props.setUpcomming) {
                if (timer.future) {
                    props.setUpcomming(true)
                } else {
                    props.setUpcomming(false)
                }
            }
        }
    }, [timer])

    return (
        <>
            {timer.timer_id === 0 ? (
                ''
            ) : timer.timervalid ? (
                <span className="timerCnt">
                    {props.theme == 'ArtAuction' ? (
                        <div className="timerWrapper">
                            {props.icon ? (
                                <span className="material-icons-outlined">timer</span>
                            ) : null}

                            {props.withText === 1 ? (
                                <h6 className="timeLabel">
                                    {timer.future
                                        ? props.startText
                                            ? props.startText
                                            : 'Starts In'
                                        : props.endText
                                        ? props.endText
                                        : 'Time Left'}
                                </h6>
                            ) : (
                                ''
                            )}

                            <div className="artAuctionTimer">
                                {timer.days > 0 ? (
                                    <h6>
                                        <span className="timerCount">{timer.days}</span>
                                        <span className="periodText">D</span>
                                    </h6>
                                ) : null}

                                <h6>
                                    <span className="timerCount">{timer.hours}</span>
                                    <span className="periodText">H</span>
                                </h6>
                                <h6>
                                    <span className="timerCount">{timer.minutes}</span>
                                    <span className="periodText">M</span>
                                </h6>
                                <h6>
                                    <span className="timerCount">{timer.seconds}</span>
                                    <span className="periodText">S</span>
                                </h6>
                            </div>
                        </div>
                    ) : props.theme == 'multiseller' ? (
                        <div className="timerWrapper">
                            {props.icon ? (
                                <span className="material-icons-outlined">timer</span>
                            ) : null}
                            <h6 className="timeLabel">
                                {!global?.storeConfigration?.timer_string_change?.value ? (
                                    <>
                                        {timer.future
                                            ? props.startText
                                                ? props.startText
                                                : 'Starts In'
                                            : props.endText
                                            ? props.endText
                                            : 'Time Left'}
                                    </>
                                ) : (
                                    <>Begins to Close</>
                                )}
                            </h6>
                            <div
                                className={
                                    timer.timer_id != 0 &&
                                    timer.timervalid &&
                                    timer.days == '00' &&
                                    timer.hours == '00' &&
                                    timer.minutes == '00' &&
                                    parseInt(timer.seconds) <= parseInt(sec_to_end)
                                        ? 'redFlashTmr'
                                        : ''
                                }
                            >
                                <div className="multiSellerTimerView">
                                    {timer.days > 0 ? (
                                        <h6>
                                            {timer.days}
                                            <span>Days</span>
                                        </h6>
                                    ) : null}

                                    <h6>
                                        {timer.hours}
                                        <span>Hrs</span>
                                    </h6>
                                    <h6>
                                        {timer.minutes}
                                        <span>Min</span>
                                    </h6>
                                    <h6>
                                        {timer.seconds}
                                        <span>Sec</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={
                                timer.timer_id != 0 &&
                                timer.timervalid &&
                                timer.days == '00' &&
                                timer.hours == '00' &&
                                timer.minutes == '00' &&
                                parseInt(timer.seconds) <= parseInt(sec_to_end)
                                    ? 'redFlashTmr'
                                    : ''
                            }
                        >
                            {props.icon ? (
                                <span className="material-icons-outlined">timer</span>
                            ) : null}
                            <span className="actualTimer">
                                {props.withText === 1
                                    ? timer.future
                                        ? props.startText
                                            ? props.startText
                                            : 'Starts In: '
                                        : props.endText
                                        ? props.endText
                                        : 'Ends In: '
                                    : timer.future
                                    ? ''
                                    : ''}
                            </span>
                            {props.live_auction != 1 ? (
                                <>
                                    {timer.days > 0
                                        ? timer.days + (props.daysText ? props.daysText : `D `)
                                        : ''}
                                    {timer.hours + (props.hoursText ? props.hoursText : `H `)}
                                    {timer.minutes + (props.minutesText ? props.minutesText : `M `)}
                                    {timer.seconds + (props.secondsText ? props.secondsText : `S`)}
                                </>
                            ) : null}
                            {props.live_auction == 1 && timer.future ? (
                                <>
                                    {timer.days > 0
                                        ? timer.days + (props.daysText ? props.daysText : `D `)
                                        : ''}
                                    {timer.hours + (props.hoursText ? props.hoursText : `H `)}
                                    {timer.minutes + (props.minutesText ? props.minutesText : `M `)}
                                    {timer.seconds + (props.secondsText ? props.secondsText : `S`)}
                                </>
                            ) : null}
                        </div>
                    )}
                </span>
            ) : (
                <span className="closedText">Auction Closed</span>
            )}
        </>
    )
}

export default Timer
