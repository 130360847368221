import {
    SUBSCRIPTION,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_MESSAGES,
    GET_ALL_REFERRALS,
    GET_FEEDBACK_FIELDS,
    GET_ALL_FEEDBACK,
    GET_PRO_FEEDBACK,
    GET_ALL_BANKS,
    SEARCH_PRODUCTS,
    GET_ABANDONED_ORDERS,
} from './pluginTypes'

export default (state, action) => {
    switch (action.type) {
        case GET_ABANDONED_ORDERS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case SUBSCRIPTION:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_MESSAGES:
            return {
                ...state,
                all_messages: action.payload,
            }
        case GET_ALL_REFERRALS:
            return {
                ...state,
                all_referrals: action.payload.data,
                total_earned: action.payload.total,
            }
        case GET_FEEDBACK_FIELDS:
            return {
                ...state,
                feedback_fields: action.payload,
            }
        case GET_ALL_FEEDBACK:
            return {
                ...state,
                all_feedback: action.payload,
            }
        case GET_PRO_FEEDBACK:
            return {
                ...state,
                single_feedback: action.payload || {},
            }
        case GET_ALL_BANKS:
            return {
                ...state,
                all_banks: action.payload,
            }
        case SEARCH_PRODUCTS:
            return {
                ...state,
                search_products: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
