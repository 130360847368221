import {
    RESPONSE_STATUS,
    GET_ALL_TIMINGS,
    CLEAR_RESPONSE,
    GET_ALL_MESSAGES,
    CLEAR_ALL_MESSAGES,
    GET_ALL_LOCATIONS,
    SET_DEFAULT_TIMINGS,
    GET_ITEM_COUNT,
} from './schedulePickupTypes'

export default (state, action) => {
    switch (action.type) {
        case GET_ALL_TIMINGS:
            return {
                ...state,
                all_timings: {
                    default_timings: [],
                    scheduled_timings: action.payload.schedule_data,
                    all_locations: action.payload.range_key,
                },
            }
        case SET_DEFAULT_TIMINGS:
            return {
                ...state,
                all_timings: {
                    ...state.all_timings,
                    default_timings: action.payload,
                },
            }
        case GET_ALL_LOCATIONS:
            return {
                ...state,
                all_messages: action.payload,
            }
        case GET_ITEM_COUNT:
            return {
                ...state,
                item_count: action.payload,
            }
        case CLEAR_ALL_MESSAGES:
            return {
                ...state,
                all_messages: [],
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: null,
            }
        default:
            return state
    }
}
