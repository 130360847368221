import React, { useState, useEffect, useContext, useRef } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../../Component/Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../../Component/Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useMediaQuery } from 'react-responsive'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import CartContext from '../../Product/context/cart/cartContext'
import ProductContext from '../../Product/context/product/productContext'
import AlertContext from '../../Product/context/alert/alertContext'
import StripeCardContext from '../../Product/context/stripe/card/cardContext'

import {
    currencyFormat,
    handleRedirectInternal,
    removeHTMLTags,
    mapData,
    getAdsImage,
} from '../../Product/common/components'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import Timer from '../../Product/common/timer'
import {
    messageHandlerSingle,
    make_an_offer_update_socket,
    qtyHandleBuynowsingle,
    reverse_auction_item,
} from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'

import './slider.css'
import { Button, Drawer, Tooltip } from '@material-ui/core'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Loaders from '../../Product/components/molecules/Loaders'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import publicIp from 'public-ip'
import MessagePlugin from '../../utils/CommonFunctionality/MessagePlugin'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import ReactImageMagnify from 'react-image-magnify'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Qtyinput from '../../Product/common/Qtyinputcard'
import MagnifyImageGallery from '../MagnifyImageGallery'

const SlidePanel = ({
    type,
    selectedLot,
    handleClose,
    viewPage,
    getAllProducts,
    from,
    audio,
    enableZoomOnHover,
    is_registered,
    private_auction,
}) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const authContext = useContext(AuthContext)
    const { setAlert } = alertContext
    const { get_all_card_details, get_skyflow_cards } = useContext(StripeCardContext)
    const { insertProductView, insert_make_proposels } = useContext(ProductContext)

    const { user, isAuthenticated } = authContext
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        product_view,
        getViewProduct,
        get_ship_station_amount,
        shipping_rate_calculation,
        clear_ship_station_amount,
    } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const { id } = useParams()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [cards, setCards] = useState([])
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [upcomming, setUpcomming] = useState(false)
    const [shipping_amount, setShipping_amount] = useState(0)
    const [homeDepot, setHomeDepot] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const [ASIN, setASIN] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let history = useHistory()
    const oneYear = new Date().setFullYear(new Date().getFullYear() + 1)
    const insertLotCount = async () => {
        const ip = await publicIp.v4()
        insertProductView({ project_id: product_view?.results?.id, user_id: ip })
    }
    const [audio_loc] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 && audio
            ? audio
            : global.storeConfigration?.bidding_sound?.value == 1
            ? new Audio('/audio/ding.mp3')
            : '',
    )
    useEffect(() => {
        if (selectedLot) {
            setLoading(true)
            getViewProduct({ product_id: selectedLot })
        } else if (id) {
            setLoading(true)
            getViewProduct({ product_id: id })
        }
    }, [selectedLot, id])

    useEffect(() => {
        setLoading(true)
        if (product_view.results) {
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] =
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([
                    {
                        original: global?.storeDetails?.logoValue,
                        thumbnail: global?.storeDetails?.logoValue,
                    },
                ])
            }

            if (parseInt(product_view.results.buynow) === 1) {
                getUserCart()
            }
            setShipping_amount(0)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
            insertLotCount()
        }
    }, [product_view.results])

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedLot) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: parseInt(selectedLot),
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results, selectedLot])

    const handleClick = async (e) => {
        if (global?.storeConfigration?.guest_user_login?.value) {
            if (
                buyer_cartitems.results.find(
                    (val) => parseInt(val.product_id) === parseInt(selectedProduct.id),
                )
            ) {
                removeFromCart({ id: selectedProduct.id })
            } else {
                addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
                setDisabled(true)
            }
        } else {
            if (!cards.length && global?.storeConfigration?.credit_card_no_need?.value != 1) {
                setAlert('Add a card to buy!', 'error')
                return handleRedirectInternal(history, 'dashboard/cards')
            }
            if (
                buyer_cartitems.results.find(
                    (val) => parseInt(val.product_id) === parseInt(selectedProduct.id),
                )
            ) {
                removeFromCart({ id: selectedProduct.id })
            } else {
                addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
                setDisabled(true)
            }
        }
    }
    useEffect(() => {
        if (global.storeConfigration?.skyflow_integration?.value == 1) {
            if (get_skyflow_cards) {
                setCards(get_skyflow_cards)
            }
        } else {
            if (get_all_card_details) {
                setCards(get_all_card_details.records)
            }
        }
    }, [get_all_card_details, get_skyflow_cards])
    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         }
    //     }
    // }, [responseStatus])

    // DO NOT ADD THIS CODE BACK. WE DO NOT WANT TO CALL THE ASIN SEARCH API ON THE FRONT END.
    // useEffect(() => {
    //     if (selectedProduct) {
    //         if (selectedProduct.hd_product_id) {
    //             const getHomedepot = async () => {
    //                 let base = process.env.REACT_APP_AUCTION_URL.replace('/api/', '')
    //                 const res = await axios.get(
    //                     `${base}/product/home_depot/${selectedProduct?.hd_product_id}?user_id=${selectedProduct?.user_id}&site_id=${global.storeDetails?.site_id}`,
    //                 )
    //                 if (res.data.success) {
    //                     setHomeDepot({
    //                         price: res.data.body.product_results.price,
    //                         date: moment(res.data.body.created_at).format('MMM Do YYYY'),
    //                         product_url: res.data.body.product_results.link,
    //                     })
    //                 }
    //             }

    //             getHomedepot()
    //         } else {
    //             setHomeDepot({
    //                 price: 0,
    //                 date: '',
    //                 product_url: '',
    //             })
    //         }
    //         if (selectedProduct.asin_no) {
    //             const getASIN = async () => {
    //                 var requestOptions = {
    //                     method: 'GET',
    //                     redirect: 'follow',
    //                 }

    //                 fetch(
    //                     `https://api.asinscope.com/products/lookup?key=${global?.asinscope_key}&asin=${selectedProduct.asin_no}`,
    //                     requestOptions,
    //                 )
    //                     .then((response) => response.text())
    //                     .then((result) => {
    //                         setASIN({
    //                             price: JSON.parse(result).items[0]?.lowestNewPrice,
    //                             product_url: `https://www.amazon.com/dp/${selectedProduct.asin_no}?%2Ftag=auction0f2-20&th=1`,
    //                         })
    //                     })
    //                     .catch((error) => console.log('error', error))
    //             }
    //             getASIN()
    //         } else {
    //             setASIN({
    //                 price: 0,
    //                 date: '',
    //                 product_url: '',
    //             })
    //         }
    //     }
    // }, [selectedProduct])
    useEffect(() => {
        if (selectedProduct) {
            if (
                selectedProduct.hd_product_id &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setHomeDepot({
                    price: selectedProduct.mprice,
                    date: '',
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setHomeDepot({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
            if (
                selectedProduct.asin_no &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setASIN({
                    price: selectedProduct.mprice,
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setASIN({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
        }
    }, [selectedProduct])
    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const validationmake_formik = Yup.object({
        proposed_amount: Yup.number()
            .positive('Enter Valid amount')
            .integer('Enter Valid amount')
            .typeError('Enter Valid amount')
            .min(1, `Enter Valid amount`)
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter Valid amount'),
    })

    const make_formik = useFormik({
        initialValues: {
            proposed_amount: '',
            project_id: '',
            user_id: '',
            seller_id: '',
            status: '',
        },
        validationSchema: validationmake_formik,
        onSubmit: async (values) => {
            make_formik.values.project_id = selectedProduct.id
            make_formik.values.user_id = user.id
            make_formik.values.seller_id = selectedProduct.user_id
            make_formik.values.status = 'Pending'
            insert_make_proposels(values)
            make_formik.values.proposed_amount = ''
        },
    })

    var make_an_offer = [
        {
            label: 'Make Offer',
            placeholder: 'Enter Price',
            type: 'number',
            class: 'col-sm-6 col-12 makeMyOffer',
            name: 'proposed_amount',
            formik: make_formik,
        },
    ]

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const qtyupdatebuynow = (message, type) => {
        qtyHandleBuynowsingle(
            message,
            viewProductRef.current,
            userRef.current,
            setSelectedProduct,
            type,
        )
    }

    const multiseller_handler = (message, type) => {
        if (parseInt(viewProductRef.current.content_head5) == 0) {
            messageHandlerSingle(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setSelectedProduct,
                type,
            )
        } else {
            if (message.type == 'reverse_auction' && message.status == 'success') {
                reverse_auction_item(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSelectedProduct,
                    'single',
                )
            }
        }
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            data.id = data.pid

            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socketAuction.on('realclosedupdates', (data) => {
            data.id = data.pid

            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })
        socket.on('real_close_update_buynow', (data) => {
            qtyupdatebuynow(data, 'qtyHandleBuynowsingl')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                data.id = data.pid

                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socketAuction.off('realclosedupdates', (data) => {
                data.id = data.pid

                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })
            socket.off('real_close_update_buynow', (data) => {
                qtyupdatebuynow(data, 'qtyHandleBuynowsingl')
            })
        }
    }, [])

    const sliderRef = useRef(null)
    const imgRef = useRef([])
    const [rotateValue, setRotateValue] = useState(0)
    // console.log(selectedProductImage, 'selectedProductImage')
    const RenderImage = () => {
        return (
            <img
                src={selectedProductImage[sliderRef?.current?.getCurrentIndex()]?.original}
                className="checkRotate"
                id={`imgRef_${sliderRef?.current?.getCurrentIndex()}`}
                onError={(e) => (e.target.src = `${global?.storeDetails?.logoValue}`)}
            />
        )
    }

    const rotateImage = (index) => {
        let newValue = rotateValue + 90
        setRotateValue(newValue)

        if (newValue >= 450) {
            setRotateValue(0)
        } else {
            setRotateValue(newValue)
        }
        document.getElementsByClassName('checkRotate')[
            sliderRef?.current?.getCurrentIndex()
        ].style.transform = `rotate(${rotateValue}deg)`
    }
    // const RenderImageZoom = () => {
    //     return (
    //         <>
    //             <Button onClick={rotateImage} className="rotateImage">
    //                 <span className="material-icons">rotate_right</span>
    //             </Button>
    //         </>
    //     )
    // }

    const RenderImageZoom = () => {
        return (
            <>
                <ReactImageMagnify
                    {...{
                        smallImage: {
                            alt: '',
                            isFluidWidth: true,
                            src: selectedProductImage[sliderRef?.current?.getCurrentIndex()]
                                ?.original,
                            enlargedImageClassName: 'enlargedImage',
                        },
                        largeImage: {
                            src: selectedProductImage[sliderRef?.current?.getCurrentIndex()]
                                ?.original,
                            width: 800,
                            height: 800,
                        },
                        className: 'hoverZoom',
                        enlargedImagePosition: 'over',
                        isHintEnabled: false,
                        // enlargedImageContainerClassName: 'customZoom',
                        enlargedImageStyle: { objectFit: 'cover' },
                    }}
                />
            </>
        )
    }

    const calculate_shipstation_amount = () => {
        var calculation_data = {
            weight: selectedProduct.weight,
            height: selectedProduct.height,
            length: selectedProduct.length,
            width: selectedProduct.width,
            toPostalCode: user.zip,
            fromPostalCode: selectedProduct.sell_zipcode ? selectedProduct.sell_zipcode : '60446',
            site_id: global.storeDetails?.site_id ? global.storeDetails.site_id : '',
            project_id: selectedProduct.id,
            toCountry: user.country,
        }
        get_ship_station_amount(calculation_data)
    }

    useEffect(() => {
        if (shipping_rate_calculation.message) {
            if (shipping_rate_calculation.message.shipping_status) {
                if (shipping_rate_calculation.message.shipping_status == 200) {
                    setShipping_amount(
                        shipping_rate_calculation.message.rate[0]?.shipmentCost
                            ? parseFloat(shipping_rate_calculation.message.rate[0].shipmentCost)
                            : '0',
                    )
                } else if (shipping_rate_calculation.message.shipping_status == 500) {
                    setAlert(
                        'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                        'error',
                    )
                } else {
                    setAlert('Please Try Again Later!', 'error')
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])

    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    const [prodAds, setProdAds] = useState({
        deskProdTop: '',
        deskProdMid: '',
        deskProdBottom: '',
        mobProdTop: '',
        mobProdMid: '',
        mobProdBottom: '',
    })

    const [activeProdAdsIndex, setActiveProdAdsIndex] = useState({
        deskProdTop: 0,
        deskProdMid: 0,
        deskProdBottom: 0,
        mobProdTop: 0,
        mobProdMid: 0,
        mobProdBottom: 0,
    })

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        setProdAds((prevAucAds) => ({
            deskProdTop: getAdsImage(1, 3, 1),
            deskProdMid: getAdsImage(1, 3, 2),
            deskProdBottom: getAdsImage(1, 3, 5),
            mobProdTop: getAdsImage(2, 3, 1),
            mobProdMid: getAdsImage(2, 3, 2),
            mobProdBottom: getAdsImage(2, 3, 5),
        }))
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveProdAdsIndex((prevActiveIndex) => ({
                deskProdTop: updateRandomIndex(prodAds?.deskProdTop),
                deskProdMid: updateRandomIndex(prodAds?.deskProdMid),
                deskProdBottom: updateRandomIndex(prodAds?.deskProdBottom),
                mobProdTop: updateRandomIndex(prodAds?.mobProdTop),
                mobProdMid: updateRandomIndex(prodAds?.mobProdMid),
                mobProdBottom: updateRandomIndex(prodAds?.mobProdBottom),
            }))
        }, imageChangeInterval)
        return () => clearInterval(interval)
    }, [prodAds])

    const render = (
        <>
            {loading ? (
                <div className="sliderLoader">
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                </div>
            ) : (
                <>
                    {selectedProduct?.id ? (
                        <div
                            style={{
                                background: '#fff',
                                height: '100vh',
                                overflowY: 'auto',
                                padding: '25px',
                            }}
                        >
                            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                <div className="siteAds adsProd">
                                    {isMobile
                                        ? prodAds?.mobProdTop[activeProdAdsIndex?.mobProdTop]
                                        : prodAds?.deskProdTop[activeProdAdsIndex?.deskProdTop]}
                                </div>
                            ) : null}
                            <div className="media-body position-relative">
                                {!viewPage && (
                                    <img
                                        src="/image/close.png"
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        className="close-img"
                                        height="35"
                                        width="35"
                                    />
                                )}
                                <div className={`prodInfoSlider`} style={{ paddingTop: '20px' }}>
                                    {isAuthenticated &&
                                    global?.storeConfigration?.bid_box_bidding_message?.value !=
                                        1 ? (
                                        selectedProduct.auction ? (
                                            type === 'buynow' ? null : (
                                                <BidStatus
                                                    bidTopStatus={selectedProduct.bidtopstatus}
                                                />
                                            )
                                        ) : null
                                    ) : null}

                                    {global?.storeConfigration?.magnify_image_gallery?.value ==
                                    1 ? (
                                        <MagnifyImageGallery images={selectedProductImage} />
                                    ) : (
                                        <ImageGallery
                                            items={selectedProductImage}
                                            thumbnailPosition="bottom"
                                            showNav={true}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            onErrorImageURL={`${global?.storeDetails?.logoValue}`}
                                            originalClass="slidImg img-fluid"
                                            // renderCustomControls={RenderImageZoom}
                                            renderItem={RenderImage}
                                            ref={sliderRef}
                                        />
                                    )}
                                    <div className="d-flex justify-content-between align-items-center pvTitleContainer flex-wrap">
                                        <div>
                                            {homeDepot.price && homeDepot.product_url ? (
                                                <h5 className="lotNo">
                                                    <a
                                                        href={homeDepot.product_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Home Depot Retail Price -{' '}
                                                        {currencyFormat(
                                                            parseFloat(homeDepot.price).toFixed(2),
                                                        )}{' '}
                                                        {/* - {homeDepot.date} */}
                                                    </a>
                                                </h5>
                                            ) : null}
                                            {ASIN.price && ASIN.product_url ? (
                                                <h5 className="lotNo">
                                                    <a
                                                        href={ASIN.product_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Amazon Retail Price -{' '}
                                                        {currencyFormat(
                                                            parseFloat(ASIN.price).toFixed(2),
                                                        )}{' '}
                                                    </a>
                                                </h5>
                                            ) : null}
                                            {global?.storeConfigration?.hide_lot_id?.value ==
                                            1 ? null : (
                                                <h5 className="lotNo">
                                                    Lot#{' '}
                                                    {selectedProduct.sku && selectedProduct.sku != 0
                                                        ? selectedProduct.sku
                                                        : selectedProduct.lot_number
                                                        ? selectedProduct.lot_number
                                                        : selectedProduct.deed_document
                                                        ? selectedProduct.deed_document
                                                        : selectedProduct.id}
                                                </h5>
                                            )}
                                            {global?.storeConfigration?.lot_sequence_order?.value ==
                                            1 ? (
                                                <h5 className="lotNo">
                                                    Lot #:{' '}
                                                    {selectedProduct.lot_number
                                                        ? selectedProduct.lot_number
                                                        : '-'}
                                                </h5>
                                            ) : null}
                                            {global?.storeConfigration?.hide_product_id_all
                                                ?.value == 1 ? null : (
                                                <h5 className="lotNo">
                                                    Product Id:{' '}
                                                    {selectedProduct.store_product_id
                                                        ? selectedProduct.store_product_id
                                                        : selectedProduct.id}
                                                </h5>
                                            )}
                                            {global.pluginConfiguration?.remote_seller?.enable ? (
                                                <div className="pv-con">
                                                    <span>Posted By:</span>
                                                    {selectedProduct.username}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {global?.storeConfigration?.show_bin_location?.value ==
                                            2 ? (
                                                <h5 className="lotNo">
                                                    Bin Location:{' '}
                                                    {selectedProduct.budget_type
                                                        ? selectedProduct.budget_type
                                                        : '-'}
                                                </h5>
                                            ) : null}
                                            {parseInt(selectedProduct.buynow) == 0 &&
                                            (parseInt(selectedProduct.lowest) ||
                                                parseInt(selectedProduct.highest)) ? (
                                                <p>
                                                    <span>Est:</span>
                                                    {`${
                                                        ' ' +
                                                        currencyFormat(selectedProduct.lowest) +
                                                        ' - ' +
                                                        currencyFormat(selectedProduct.highest)
                                                    }`}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {global?.storeConfigration?.unique_slot4?.value != 1 ? (
                                            <div className="pvMiscActions">
                                                <a
                                                    target="_blank"
                                                    className="searchExt"
                                                    rel="noreferrer"
                                                    href={`https://www.google.com/search?q=${selectedProduct.title}`}
                                                >
                                                    <span className="material-icons">search</span>
                                                </a>
                                                <FavouriteCheckbox
                                                    watchlisted={selectedProduct.wlistpop}
                                                    data={selectedProduct}
                                                    project_id={selectedProduct.id}
                                                    getAllProducts={getAllProducts}
                                                    setSelectedProduct={setSelectedProduct}
                                                    from={from}
                                                />
                                                <Email
                                                    path={`/product/${selectedProduct.id}`}
                                                    subject={selectedProduct.title}
                                                    body={removeHTMLTags(
                                                        selectedProduct.description,
                                                    )}
                                                />
                                                <Facebook
                                                    path={`/product/${selectedProduct.id}`}
                                                    title={selectedProduct.title}
                                                />
                                                {global?.storeConfigration?.disable_whatsapp_sharing
                                                    ?.value == 1 ? null : (
                                                    <Whatsapp
                                                        path={`/product/${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    />
                                                )}
                                                {global?.storeConfigration?.disable_twitter_sharing
                                                    ?.value == 1 ? null : (
                                                    <Twitter
                                                        path={`/product/${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                    />
                                                )}
                                                {global?.storeConfigration
                                                    ?.disable_pinterest_sharing?.value ==
                                                1 ? null : (
                                                    <Pinterest
                                                        path={`/product/${selectedProduct.id}`}
                                                        title={selectedProduct.title}
                                                        media={
                                                            `${
                                                                selectedProduct.content_head1 ===
                                                                '0'
                                                                    ? process.env
                                                                          .REACT_APP_BASE_URL +
                                                                      'uploads/product/'
                                                                    : process.env
                                                                          .REACT_APP_PRODUCT_IMAGE_URL
                                                            }` + selectedProduct.avatar
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <h3
                                        className="plTitle"
                                        style={{ color: 'var(--primColor)' }}
                                        dangerouslySetInnerHTML={{
                                            __html: selectedProduct.title,
                                        }}
                                    />
                                    <div className="pv-con">
                                        <span>Description:</span>
                                    </div>
                                    <p
                                        key={selectedProduct.id}
                                        dangerouslySetInnerHTML={{
                                            __html: selectedProduct.description,
                                        }}
                                    ></p>
                                    {selectedProduct.location &&
                                    !selectedProduct?.location?.includes('[') ? (
                                        <div className="d-flex align-items-baseline justify-content-start">
                                            <div className="pv-con">
                                                <span>Warehouse Location:</span>
                                            </div>
                                            <p className="">{selectedProduct.location}</p>
                                        </div>
                                    ) : null}
                                    <div className="d-flex align-items-baseline justify-content-start">
                                        <div className="pv-con">
                                            <span>Condition:</span>
                                        </div>
                                        {global?.storeConfigration?.condition_field?.value == 1 ? (
                                            <p>
                                                {selectedProduct.extra_fields
                                                    ? JSON.parse(selectedProduct.extra_fields)
                                                          .condition_field
                                                    : '-'}
                                            </p>
                                        ) : global?.condition_data?.find(
                                              (val) =>
                                                  val.condition == selectedProduct.auctionnotes,
                                          )?.content ? (
                                            <>
                                                <Tooltip
                                                    title={
                                                        global?.condition_data?.find(
                                                            (val) =>
                                                                val.condition ==
                                                                selectedProduct.auctionnotes,
                                                        )?.content
                                                    }
                                                    arrow
                                                    placement="top-start"
                                                    opensOn="Hover"
                                                    className="tooling"
                                                >
                                                    <span>i</span>
                                                </Tooltip>
                                                <p
                                                    // className="ml-4"
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedProduct.auctionnotes,
                                                    }}
                                                ></p>
                                            </>
                                        ) : (
                                            <p>
                                                {selectedProduct.auctionnotes
                                                    ? selectedProduct.auctionnotes
                                                    : selectedProduct.conditionunique
                                                    ? selectedProduct.conditionunique
                                                    : null}
                                            </p>
                                        )}
                                    </div>
                                    {global?.storeConfigration?.need_transferable?.value == 1 ? (
                                        <div className="d-flex align-items-baseline justify-content-start">
                                            <div className="pv-con">
                                                <span>Transferable:</span>
                                            </div>
                                            {selectedProduct.transferable ? (
                                                <Tooltip
                                                    title={
                                                        selectedProduct.transferable == 'Yes'
                                                            ? 'This item can be transfered to a different warehouse location for pickup.'
                                                            : selectedProduct.transferable == 'No'
                                                            ? 'This item must be picked up from its original location.'
                                                            : ''
                                                    }
                                                    arrow
                                                    placement="top-start"
                                                    open={showTooltip}
                                                    onOpen={() => setShowTooltip(true)}
                                                    onClose={() => setShowTooltip(false)}
                                                    opensOn="Click"
                                                    className="tooling"
                                                >
                                                    <span
                                                        onClick={() => setShowTooltip(!showTooltip)}
                                                    >
                                                        i
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                            <p>
                                                {selectedProduct.transferable
                                                    ? selectedProduct.transferable
                                                    : ' - '}
                                            </p>
                                        </div>
                                    ) : null}

                                    {global.pluginConfiguration?.most_viewed?.enable == 1 &&
                                    selectedProduct.viwers ? (
                                        <>
                                            {' '}
                                            <div className="pv-con">
                                                <span>Viewers:</span>
                                            </div>
                                            <p>{selectedProduct.viwers}</p>
                                        </>
                                    ) : null}
                                    {selectedProduct.mprice ? (
                                        <>
                                            <div className="pv-con">
                                                <span>Retail Price</span>
                                            </div>
                                            <p>
                                                {currencyFormat(
                                                    parseFloat(selectedProduct.mprice).toFixed(2),
                                                )}
                                            </p>
                                        </>
                                    ) : null}
                                    <div className="row">
                                        <div className="col-md-8">
                                            {selectedProduct.auction == 1 ? (
                                                <div className="pv-con">
                                                    <span>
                                                        {selectedProduct.bids
                                                            ? 'Current '
                                                            : 'Start '}
                                                        Price:
                                                    </span>
                                                    {currencyFormat(
                                                        parseFloat(selectedProduct.wprice).toFixed(
                                                            2,
                                                        ),
                                                    )}
                                                    {selectedProduct.bids &&
                                                    selectedProduct.rprice &&
                                                    parseFloat(selectedProduct.rprice) > 0 ? (
                                                        parseFloat(selectedProduct?.wprice) >=
                                                        parseFloat(selectedProduct?.rprice) ? (
                                                            <span style={{ color: '#838383' }}>
                                                                (Reserve has been met)
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: '#8B0000' }}>
                                                                (Reserve has not been met yet)
                                                            </span>
                                                        )
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="pv-con">
                                                        <span>Buy now Price:</span>
                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.bprice,
                                                            ).toFixed(2),
                                                        )}
                                                    </div>
                                                    {global?.storeConfigration?.buy_now_with_qty
                                                        ?.value == 1 &&
                                                    global?.storeConfigration?.unique_slot4
                                                        ?.value != 1 ? (
                                                        <div className="pv-con">
                                                            <span>Available Qty:</span>
                                                            {selectedProduct.qty}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {global?.storeConfigration?.qty_input_slider
                                                        ?.value == 1 ? (
                                                        <div>
                                                            <Qtyinput
                                                                type={'slider'}
                                                                total_qty={selectedProduct.qty}
                                                                qty={'1'}
                                                                product_id={selectedProduct.id}
                                                                disabled={disabled}
                                                                history={history}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {global?.storeConfigration?.unique_slot4
                                                        ?.value == 1 ? (
                                                        <>
                                                            <div className="pv-con">
                                                                <span>Total Qty:</span>
                                                                {selectedProduct.qty +
                                                                    selectedProduct.sold}
                                                            </div>
                                                            <div className="pv-con">
                                                                <span>Available Qty:</span>
                                                                {selectedProduct.qty}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                            <div className="pv-con">
                                                {type !== 'buynow' && selectedProduct.auction ? (
                                                    <>
                                                        <span>Total Bids: </span>
                                                        <a
                                                            className="tb-link"
                                                            onClick={() =>
                                                                selectedProduct.bids &&
                                                                selectedProduct.bids !== 0 &&
                                                                !selectedProduct.is_past &&
                                                                setHistoryData({
                                                                    product_id: selectedProduct.id,
                                                                    end: moment(
                                                                        selectedProduct.date_closed,
                                                                    ).isValid()
                                                                        ? selectedProduct.date_closed
                                                                        : oneYear,
                                                                })
                                                            }
                                                        >
                                                            {selectedProduct.bids
                                                                ? selectedProduct.bids
                                                                : 0}
                                                        </a>
                                                    </>
                                                ) : (
                                                    ''
                                                )}

                                                {parseInt(selectedProduct.buynow) == 0 ? (
                                                    <div className="pv-con1 svInfo">
                                                        <span>{`Closing Date: `}</span>
                                                        {moment(
                                                            moment(
                                                                selectedProduct.date_closed,
                                                            ).isValid()
                                                                ? selectedProduct.date_closed
                                                                : oneYear,
                                                        ).format('MMM Do YYYY, h:mm:ss a')}{' '}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {global.storeConfigration?.show_buyer_premium
                                                    ?.value == 1 ? (
                                                    <div className="pv-con1 svInfo">
                                                        <span>{"Buyer's Premium:"}</span>
                                                        {selectedProduct.buyerpremium_percentage +
                                                            ' %'}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {/*Shipping Plugin Added Flow Start*/}
                                            {parseInt(selectedProduct.hasshipping) &&
                                            global.pluginConfiguration?.shipping?.enable &&
                                            isAuthenticated ? (
                                                <div className="pv-con">
                                                    <p>
                                                        Rates based on Shipping Address on profile.{' '}
                                                        Update
                                                        <Link to="/dashboard/profile">here</Link>
                                                    </p>
                                                    {shipping_amount ? (
                                                        <>
                                                            {/* <div className="pv-con"> */}
                                                            <span>Shipping Amount</span>

                                                            {currencyFormat(
                                                                parseFloat(shipping_amount).toFixed(
                                                                    2,
                                                                ),
                                                            )}
                                                            {/* </div> */}
                                                        </>
                                                    ) : (
                                                        <PrimaryButton
                                                            label="Calculate Shipping"
                                                            btnSize="small"
                                                            onClick={() =>
                                                                calculate_shipstation_amount()
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {/*Shipping Plugin Added Flow End*/}
                                        </div>
                                        {selectedProduct.auction == 1 ? (
                                            <div className="col-md-4 text-center-md mt-90 hide-timmer-sunrise">
                                                <div className="timer-wrapper">
                                                    <div>
                                                        <img
                                                            src="/image/timer.svg"
                                                            alt=""
                                                            className="lv-timer"
                                                        />
                                                    </div>
                                                    {selectedProduct.market_status != 'open' ||
                                                    selectedProduct.productClosed ? (
                                                        'Closed'
                                                    ) : selectedProduct.extend ||
                                                      selectedProduct.agent_id ? (
                                                        <span className="tmm">
                                                            <Timer
                                                                date_added={
                                                                    selectedProduct.date_added
                                                                }
                                                                date_closed={
                                                                    moment(
                                                                        selectedProduct.date_closed,
                                                                    ).isValid()
                                                                        ? selectedProduct.date_closed
                                                                        : oneYear
                                                                }
                                                                withText={1}
                                                                endText={'Ends in' + ':'}
                                                                startText={'Starts in' + ':'}
                                                                setUpcomming={setUpcomming}
                                                                audio={audio_loc}
                                                            ></Timer>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Timer
                                                                date_added={
                                                                    selectedProduct.date_added
                                                                }
                                                                date_closed={
                                                                    moment(
                                                                        selectedProduct.date_closed,
                                                                    ).isValid()
                                                                        ? selectedProduct.date_closed
                                                                        : oneYear
                                                                }
                                                                withText={1}
                                                                endText={'Ends in' + ':'}
                                                                startText={'Starts in' + ':'}
                                                                setUpcomming={setUpcomming}
                                                                audio={audio_loc}
                                                            ></Timer>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                        <div className="siteAds adsProd">
                                            {isMobile
                                                ? prodAds?.mobProdMid[
                                                      activeProdAdsIndex?.mobProdMid
                                                  ]
                                                : prodAds?.deskProdMid[
                                                      activeProdAdsIndex?.deskProdMid
                                                  ]}
                                        </div>
                                    ) : null}
                                    {selectedProduct.market_status != 'open' ||
                                    selectedProduct.productClosed ? (
                                        <b>Lot Closed</b>
                                    ) : (
                                        <div
                                            className={`sl-pbids slidView ${
                                                !isAuthenticated && 'notLogged'
                                            }`}
                                        >
                                            {isAuthenticated &&
                                            global?.storeConfigration?.bid_box_bidding_message
                                                ?.value == 1 ? (
                                                selectedProduct.auction ? (
                                                    type === 'buynow' ? null : (
                                                        <BidStatus
                                                            bidTopStatus={
                                                                selectedProduct.bidtopstatus
                                                            }
                                                        />
                                                    )
                                                ) : null
                                            ) : null}
                                            {isAuthenticated ||
                                            global?.storeConfigration?.guest_user_login?.value ? (
                                                <>
                                                    {selectedProduct.auction &&
                                                    selectedProduct?.user_id == user?.id ? (
                                                        <p>You cannot bid your own product!</p>
                                                    ) : global.pluginConfiguration?.reverse_auction
                                                          ?.enable &&
                                                      selectedProduct.content_head5 == 1 ? (
                                                        <Biddingforwardandreverse
                                                            theme="multiseller"
                                                            data={selectedProduct}
                                                            hard="1"
                                                            is_registered={is_registered}
                                                            private_auction={private_auction}
                                                        />
                                                    ) : global.pluginConfiguration?.make_an_offer
                                                          ?.enable ? (
                                                        <>
                                                            {selectedProduct?.make_an_offer_io &&
                                                            upcomming ? (
                                                                ''
                                                            ) : (
                                                                <>
                                                                    {' '}
                                                                    {selectedProduct.auction ? (
                                                                        type === 'buynow' &&
                                                                        !selectedProduct.auction ? null : (
                                                                            <>
                                                                                {global
                                                                                    .storeConfigration
                                                                                    ?.hard_bid
                                                                                    ?.value ==
                                                                                    '1' && (
                                                                                    <>
                                                                                        <p className="slidViewBtnLabel">
                                                                                            <span className="material-icons">
                                                                                                info
                                                                                            </span>
                                                                                            Hard Bid
                                                                                            - Bid on
                                                                                            the
                                                                                            current
                                                                                            price
                                                                                        </p>
                                                                                        <Bidding
                                                                                            data={
                                                                                                selectedProduct
                                                                                            }
                                                                                            hard="1"
                                                                                            audio={
                                                                                                audio_loc
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    ) : null}
                                                                    {selectedProduct.auction ? (
                                                                        type === 'buynow' &&
                                                                        !selectedProduct.auction ? null : (
                                                                            <>
                                                                                {global
                                                                                    .storeConfigration
                                                                                    ?.proxy_bid
                                                                                    ?.value ==
                                                                                    '1' && (
                                                                                    <>
                                                                                        <p className="slidViewBtnLabel">
                                                                                            <span className="material-icons">
                                                                                                info
                                                                                            </span>
                                                                                            Proxy
                                                                                            Bid -
                                                                                            System
                                                                                            will
                                                                                            auto bid
                                                                                            upto the
                                                                                            amount
                                                                                            entered
                                                                                        </p>
                                                                                        <Bidding
                                                                                            data={
                                                                                                selectedProduct
                                                                                            }
                                                                                            hard="0"
                                                                                            audio={
                                                                                                audio_loc
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {selectedProduct.auction ? (
                                                                type === 'buynow' &&
                                                                !selectedProduct.auction ? null : (
                                                                    <>
                                                                        {global.storeConfigration
                                                                            ?.hard_bid?.value ==
                                                                            '1' && (
                                                                            <>
                                                                                <p className="slidViewBtnLabel">
                                                                                    <span className="material-icons">
                                                                                        info
                                                                                    </span>
                                                                                    Hard Bid - Bid
                                                                                    on the current
                                                                                    price
                                                                                </p>
                                                                                <Bidding
                                                                                    data={
                                                                                        selectedProduct
                                                                                    }
                                                                                    hard="1"
                                                                                    audio={
                                                                                        audio_loc
                                                                                    }
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )
                                                            ) : null}
                                                            {selectedProduct.auction ? (
                                                                type === 'buynow' &&
                                                                !selectedProduct.auction ? null : (
                                                                    <>
                                                                        {global.storeConfigration
                                                                            ?.proxy_bid?.value ==
                                                                            '1' && (
                                                                            <>
                                                                                <p className="slidViewBtnLabel">
                                                                                    <span className="material-icons">
                                                                                        info
                                                                                    </span>
                                                                                    Proxy Bid -
                                                                                    System will auto
                                                                                    bid upto the
                                                                                    amount entered
                                                                                </p>
                                                                                <Bidding
                                                                                    data={
                                                                                        selectedProduct
                                                                                    }
                                                                                    hard="0"
                                                                                    audio={
                                                                                        audio_loc
                                                                                    }
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )
                                                            ) : null}
                                                        </>
                                                    )}
                                                    {global?.storeConfigration?.qty_input_slider
                                                        ?.value != 1 ? (
                                                        <>
                                                            {selectedProduct.buynow &&
                                                            selectedProduct?.user_id == user?.id ? (
                                                                <p>
                                                                    You cannot buy your own product!
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {selectedProduct.buynow ? (
                                                                        selectedProduct.wprice >=
                                                                        selectedProduct.bprice ? null : (
                                                                            <>
                                                                                {user?.id ==
                                                                                selectedProduct.user_id ? null : (
                                                                                    <div className="">
                                                                                        <PrimaryButton
                                                                                            onClick={
                                                                                                handleClick
                                                                                            }
                                                                                            disabled={
                                                                                                user?.status ==
                                                                                                'suspended'
                                                                                            }
                                                                                        >
                                                                                            {disabled
                                                                                                ? 'Remove from cart'
                                                                                                : `Buy now for ${currencyFormat(
                                                                                                      parseFloat(
                                                                                                          selectedProduct.bprice,
                                                                                                      ).toFixed(
                                                                                                          2,
                                                                                                      ),
                                                                                                  )}`}
                                                                                        </PrimaryButton>
                                                                                    </div>
                                                                                )}
                                                                                <br />
                                                                            </>
                                                                        )
                                                                    ) : null}

                                                                    {global.pluginConfiguration
                                                                        ?.make_an_offer?.enable &&
                                                                    selectedProduct?.make_an_offer_io &&
                                                                    selectedProduct?.buynow &&
                                                                    !selectedProduct?.auction &&
                                                                    selectedProduct?.user_id !=
                                                                        user?.id ? (
                                                                        <div className="row">
                                                                            {mapData(make_an_offer)}
                                                                            <div className="col-sm-6 col-12">
                                                                                <PrimaryButton
                                                                                    onClick={(e) =>
                                                                                        selectedProduct.market_status ==
                                                                                        'open'
                                                                                            ? make_formik.handleSubmit()
                                                                                            : ''
                                                                                    }
                                                                                    disabled={
                                                                                        selectedProduct.market_status ==
                                                                                        'sold'
                                                                                    }
                                                                                >
                                                                                    {selectedProduct.market_status ==
                                                                                    'sold'
                                                                                        ? 'Lot Sold'
                                                                                        : `Submit Offer`}
                                                                                </PrimaryButton>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                    {global.pluginConfiguration
                                                                        ?.make_an_offer?.enable &&
                                                                    selectedProduct?.make_an_offer_io &&
                                                                    selectedProduct?.auction &&
                                                                    !selectedProduct?.buynow &&
                                                                    upcomming &&
                                                                    selectedProduct?.user_id !=
                                                                        user?.id ? (
                                                                        <div className="row">
                                                                            {mapData(make_an_offer)}
                                                                            <div className="col-sm-6 col-12">
                                                                                <PrimaryButton
                                                                                    onClick={(e) =>
                                                                                        selectedProduct.market_status ==
                                                                                        'open'
                                                                                            ? make_formik.handleSubmit()
                                                                                            : ''
                                                                                    }
                                                                                    disabled={
                                                                                        selectedProduct.market_status ==
                                                                                        'sold'
                                                                                    }
                                                                                >
                                                                                    {selectedProduct.market_status ==
                                                                                    'sold'
                                                                                        ? 'Lot Sold'
                                                                                        : `Submit Offer`}
                                                                                </PrimaryButton>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {global?.storeConfigration?.qty_input_slider
                                                        ?.value != 1 ? (
                                                        <PrimaryButton
                                                            label="Login to continue"
                                                            btnSize="small"
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'login',
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                    <div className="siteAds adsProd">
                                        {isMobile
                                            ? prodAds?.mobProdBottom[
                                                  activeProdAdsIndex?.mobProdBottom
                                              ]
                                            : prodAds?.deskProdBottom[
                                                  activeProdAdsIndex?.deskProdBottom
                                              ]}
                                    </div>
                                ) : null}
                            </div>
                            <Bidhistory
                                modaltitle="Bid history"
                                open={Object.keys(historyData).length !== 0}
                                value={historyData}
                                handleClose={() => setHistoryData({})}
                            />
                            {user && global.pluginConfiguration?.messages?.enable == 1 && (
                                <MessagePlugin product={selectedProduct} />
                            )}
                        </div>
                    ) : (
                        <div className="container pt-5">
                            <NoRecordsFound />
                        </div>
                    )}
                </>
            )}
        </>
    )
    return viewPage ? (
        render
    ) : (
        <Drawer
            className="rightDrawer productViewDrawer"
            anchor={'right'}
            open={selectedLot}
            onClose={handleClose}
        >
            {render}
        </Drawer>
    )
}

export default SlidePanel
