import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './MultisellerListCard.css'
import Timer from '../../Product/common/timer'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import AddToCart from '../../utils/CommonFunctionality/Cart/AddToCart'
import Bidhistory from '../Bidhistory'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ReadMoreReact from 'read-more-react'
import PaginationComponent from '../Pagination'
import AuthContext from '../../Product/context/auth/authContext'
import { currencyFormat } from '../../Product/common/components'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { getFilePath } from '../../utils/commonFunctions'
import moment from 'moment'
import ReadMore from '../ReadMore'
import { Tooltip } from '@material-ui/core'
import MakeAnoffer from '../../Product/common/make_offer'
function TableView(props) {
    const [selectedHistory, setSelectedHistory] = useState({})
    const [lotDescription, setLotDescription] = useState([])
    let paragraph = '<p></p>'
    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const [showTooltip, setShowTooltip] = useState(false)
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const [showDescription, setShowDescription] = useState(false)
    const [bidText, setBidText] = useState('')
    /*eslint-disable*/
    const removeparagraphtag = (val) => {
        var data = ''
        if (val) {
            data = val.replace(/&#34;/g, '"')
            data = data.replace(/<p>/g, '')
            data = data.split('</p>').join('')
            return data
        } else {
            return data
        }
    }
    // const getBidText = (data) => {
    //     if (isAuthenticated && data.latestbid != null && user?.id != undefined) {
    //         if (data.auction) {
    //             if (
    //                 (data.highbid || data.latestbid_user == user?.id) &&
    //                 data.latestbid >= data.rprice
    //             ) {
    //                 if (data.market_status == 'open') {
    //                     return 'Winning'
    //                 } else if (data.market_status == 'sold') {
    //                     return 'Won'
    //                 }
    //             } else if ((!data.highbid || data.latestbid_user != user?.id) && data.cbidnot) {
    //                 if (data.market_status == 'open') {
    //                     return 'Outbid'
    //                 } else if (data.market_status == 'sold') {
    //                     return 'Lost'
    //                 }
    //             } else {
    //                 return ''
    //             }
    //         }
    //     }
    // }
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data.latestbid_user != null || data.latestbid != null) &&
            user?.id != undefined
        ) {
            if (data.auction) {
                if (
                    (data.latestbid_user == user?.id || data.highbid) &&
                    data.wprice >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        return 'Winning'
                    } else if (data.market_status == 'sold') {
                        return 'Won'
                    }
                } else if (
                    data.latestbid_user != user?.id &&
                    (data.currentUserBidAmount || data.bid_or_not || data.cbidnot)
                ) {
                    if (data.market_status == 'open') {
                        return 'Outbid'
                    } else if (data.market_status == 'sold') {
                        return 'Lost'
                    }
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }
    }
    useEffect(() => {
        if (props.lotData?.length) {
            setLotDescription(new Array(props.lotData?.length).fill(false))
        }
    }, [props.lotData])
    const handleLotDescription = (index) => {
        let temp = lotDescription
        temp[index] = !temp[index]
        setLotDescription([...temp])
    }
    return (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Image</th>
                        {global?.storeConfigration?.lot_sequence_order?.value == 1 ? (
                            <th>Lot #</th>
                        ) : null}
                        <th>Title</th>
                        <th>Description</th>
                        <th>Closing in</th>
                        <th>Current bid</th>
                        <th>No. of bids</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props.lotData.map((item, index) => {
                        return (
                            <tr key={index}>
                                {/*eslint-disable*/}

                                <td className="clearfix sl-img-wrap" data-title="Image">
                                    <div className="position-relative" style={{ height: '100%' }}>
                                        <div className="buyTableAucTab lctnFavWrapper row d-flex align-items-center justify-content-between">
                                            {item.market_status == 'open' && (
                                                <>
                                                    {props.cardTheme == 'liquidationTwo' ||
                                                    global.storeDetails?.theme === 18 ? (
                                                        <div className="lctnTxtWrpr d-flex align-items-center justify-content-end">
                                                            <span className="material-icons">
                                                                location_on
                                                            </span>
                                                            {console.log(item, 'item')}
                                                            <span className="location-class">
                                                                {localStorage.getItem(
                                                                    'globalLocation',
                                                                )
                                                                    ? localStorage.getItem(
                                                                          'globalLocation',
                                                                      )
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                    <FavouriteCheckbox
                                                        watchlisted={
                                                            item.watchlistid ||
                                                            item.watchlisted ||
                                                            item.isFav
                                                        }
                                                        project_id={item.id}
                                                        from="card"
                                                        viewProduct={props.lotData}
                                                        setViewProduct={props.setSubLotData}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div className="tblImgWrpr">
                                            {global?.storeConfigration?.sold_status?.value ==
                                            '1' ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {item.market_status === 'sold' && (
                                                        <div className="soldWrapper">
                                                            <p>SOLD</p>
                                                        </div>
                                                    )}
                                                    {item.market_status === 'closed' && (
                                                        <div className="soldWrapper">
                                                            <p className="unsolding">UNSOLD</p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {item.avatar_vc != '' ||
                                            item.avatar_vc != undefined ||
                                            item.avatar != '' ? (
                                                <LazyLoadImage
                                                    height="100%"
                                                    width="100%"
                                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                                    effect="blur"
                                                    src={`${
                                                        item.content_head1 === '0' ||
                                                        item.store_id === 0
                                                            ? process.env.REACT_APP_BASE_URL +
                                                              'uploads/product/'
                                                            : process.env
                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                    }${
                                                        global.storeConfigration?.image_compression
                                                            ?.value == 1
                                                            ? 'thumb_'
                                                            : ''
                                                    }${
                                                        (item?.lotImages &&
                                                            item?.lotImages[0]?.file_name) ||
                                                        item?.file_name ||
                                                        item?.avatar_vc ||
                                                        item?.avatar
                                                    }`}
                                                    alt=""
                                                    onError={(e) =>
                                                        (e.target.src = `${global?.storeDetails?.logoValue}`)
                                                    }
                                                    onClick={() => props.selectedPostion(item.id)}
                                                />
                                            ) : item.productImagesName != '' ? (
                                                <img
                                                    src={getFilePath(
                                                        item.productImagesName,
                                                        item.auctionlot_title,
                                                        true,
                                                    )}
                                                    alt=""
                                                    onError={(e) =>
                                                        (e.target.src = `${global?.storeDetails?.logoValue}`)
                                                    }
                                                    onClick={() => props.selectedPostion(item.id)}
                                                />
                                            ) : (
                                                <img
                                                    src={`${global?.storeDetails?.logoValue}`}
                                                    className="pro-img img-fluid"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </td>

                                {global?.storeConfigration?.lot_sequence_order?.value == 1 ? (
                                    <td
                                        className="clearfix responsiveView auctionLotLot tableTit"
                                        data-title="Lot#"
                                        onClick={() => props.selectedPostion(item.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {item.lot_number || '-'}
                                    </td>
                                ) : null}
                                <td
                                    className="clearfix responsiveView auctionLotTitle tableTit"
                                    data-title="Title"
                                    // dangerouslySetInnerHTML={{
                                    //     __html: item.title,
                                    // }}
                                >
                                    <ReadMore
                                        data={item.title}
                                        limit={50}
                                        onClick={() => props.selectedPostion(item.id)}
                                    />
                                </td>
                                {/* <td
                                    className="clearfix responsiveView auctionLotId"
                                    data-title="Id"
                                >
                                    <ReadMore data={item.id} limit={50} />
                                </td> */}
                                <td
                                    className="clearfix responsiveView auctionLotTitle auctionDespn"
                                    data-title="Description"
                                >
                                    <>
                                        <ReadMore
                                            data={item.description}
                                            limit={50}
                                            onClick={() => props.selectedPostion(item.id)}
                                        />
                                        {/* <>
                                                {(item.description &&
                                                    item.description?.length < 150) ||
                                                lotDescription[index] ? (
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.description,
                                                            }}
                                                        ></div>
                                                        {item.description?.length > 150 && (
                                                            <span
                                                                className="cursorDecoy"
                                                                onClick={() =>
                                                                    handleLotDescription(index)
                                                                }
                                                            >
                                                                <b>
                                                                    <u>HIDE</u>
                                                                </b>
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item.description &&
                                                                    item.description.slice(0, 150) +
                                                                        '...',
                                                            }}
                                                        ></div>
                                                        <span
                                                            className="cursorDecoy"
                                                            onClick={() =>
                                                                handleLotDescription(index)
                                                            }
                                                        >
                                                            <b>
                                                                <u>READ MORE</u>
                                                            </b>
                                                        </span>
                                                    </>
                                                )}
                                            </> */}
                                    </>

                                    {/* <ReadMoreReact
                                        text={removeparagraphtag(item.description)}
                                        min={150}
                                        ideal={200}
                                        max={400}
                                        readMoreText={'READ MORE'}
                                    /> */}
                                </td>
                                <td
                                    className="clearfix responsiveView tableTimer"
                                    data-title="Closing In"
                                    onClick={() => props.selectedPostion(item.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {item.auction != 1 && item.buynow == 1 ? (
                                        '-'
                                    ) : (
                                        <>
                                            {item.market_status != 'open' || item.productClosed ? (
                                                'Closed'
                                            ) : item.extend || item.agent_id == 1 ? (
                                                <span className="tmm">
                                                    <Timer
                                                        date_added={item.date_added}
                                                        date_closed={item.date_closed}
                                                        withText={1}
                                                        endText={'Ends in' + ':'}
                                                        startText={'Starts in' + ':'}
                                                        audio={props.audio}
                                                        viewProduct={props.viewProduct}
                                                        setViewProduct={props.setViewProduct}
                                                        product_id={item.id}
                                                    ></Timer>
                                                </span>
                                            ) : (
                                                <span>
                                                    <Timer
                                                        date_added={item.date_added}
                                                        date_closed={item.date_closed}
                                                        withText={1}
                                                        endText={'Ends in' + ':'}
                                                        startText={'Starts in' + ':'}
                                                        audio={props.audio}
                                                        product_id={item.id}
                                                        viewProduct={props.viewProduct}
                                                        setViewProduct={props.setViewProduct}
                                                    ></Timer>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </td>
                                {/* <td>{`$${item.sprice}`}</td> */}
                                <td
                                    className="clearfix price-con responsiveView"
                                    data-title="Current Bid"
                                    onClick={() => props.selectedPostion(item.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {/* {item.latestbid
                                        ? `US$ ${parseFloat(item.latestbid).toFixed(2)}`
                                        : 'N/A'} */}
                                    <span className="d-block Pricecutr">
                                        {item.market_status == 'sold'
                                            ? 'Sold'
                                            : item.nobids || item.bid_count
                                            ? 'Current'
                                            : `${
                                                  item.auction != 1 && item.buynow == 1
                                                      ? 'Buy now'
                                                      : 'Start'
                                              }`}{' '}
                                        Price:{' '}
                                        <span className="">
                                            {currencyFormat(
                                                parseFloat(
                                                    item.auction != 1 && item.buynow == 1
                                                        ? item.bprice
                                                        : item.wprice,
                                                ).toFixed(2),
                                            )}
                                        </span>
                                    </span>
                                    {item.latestbid && item.rprice && parseInt(item.rprice) > 0 ? (
                                        parseFloat(item.latestbid) >= parseFloat(item.rprice) ? (
                                            <span style={{ color: '#838383' }}>
                                                (Reserve has been met)
                                            </span>
                                        ) : (
                                            <span style={{ color: '#8B0000' }}>
                                                (Reserve has not been met yet)
                                            </span>
                                        )
                                    ) : (
                                        ''
                                    )}
                                    {global.storeConfigration?.condition_with_color?.value == 1 ? (
                                        <span className="d-block Pricecutr">
                                            Condition:{' '}
                                            <span
                                                className={`prcTxtVal ${
                                                    item.auctionnotes
                                                        ?.trim()
                                                        ?.toLowerCase()
                                                        ?.includes('new')
                                                        ? 'badge badge-success text-white'
                                                        : item.auctionnotes
                                                              ?.trim()
                                                              ?.toLowerCase()
                                                              ?.includes('open box')
                                                        ? 'badge badge-primary text-white'
                                                        : item.auctionnotes
                                                              ?.trim()
                                                              ?.toLowerCase()
                                                              ?.includes('damage')
                                                        ? 'badge badge-danger text-white'
                                                        : ''
                                                }`}
                                                title={item.auctionnotes ? item.auctionnotes : '-'}
                                            >
                                                {item.auctionnotes ? item.auctionnotes : '-'}
                                            </span>
                                        </span>
                                    ) : null}

                                    {global.storeConfigration?.show_condition?.value == 1 &&
                                    props.cardTheme != 'liquidationTwo' ? (
                                        <span className="d-block Pricecutr">
                                            Condition:
                                            <span className="conditionDet">
                                                <ReadMore
                                                    data={
                                                        item.auctionnotes ? item.auctionnotes : '-'
                                                    }
                                                    limit={50}
                                                />
                                            </span>
                                        </span>
                                    ) : null}

                                    {global?.storeConfigration?.need_transferable?.value == 1 ? (
                                        <span style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                                            Transferable:
                                            {item.transferable ? (
                                                <Tooltip
                                                    title={
                                                        item.transferable == 'Yes'
                                                            ? 'This item can be transfered to a different warehouse location for pickup.'
                                                            : item.transferable == 'No'
                                                            ? 'This item must be picked up from its original location.'
                                                            : ''
                                                    }
                                                    arrow
                                                    placement="top-start"
                                                    open={showTooltip}
                                                    onOpen={() => setShowTooltip(true)}
                                                    onClose={() => setShowTooltip(false)}
                                                    className="tooling"
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <span
                                                        onClick={() => setShowTooltip(!showTooltip)}
                                                    >
                                                        i
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                            <span className="conditionDet">
                                                {item.transferable ? item.transferable : ' - '}
                                            </span>
                                        </span>
                                    ) : null}
                                </td>
                                <td
                                    className="clearfix responsiveView"
                                    id="presScroll"
                                    data-title="No. of Bids"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <a
                                        className="tb-link"
                                        href="#presScroll"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (!item.is_past) {
                                                openHistory({
                                                    product_id: item.id,
                                                    end: item.date_closed,
                                                })
                                            }
                                        }}
                                    >
                                        {item.nobids}
                                    </a>
                                </td>
                                <td className="sl-pbids clearfix" data-title="Action">
                                    {item.market_status != 'open' ||
                                    item.productClosed ||
                                    (item.date_closed &&
                                        moment(item.date_closed).isValid() &&
                                        props.auctionTimer &&
                                        moment(props.auctionTimer).isValid() &&
                                        new Date(item.date_closed).getTime() -
                                            new Date(props.auctionTimer).getTime() <
                                            0) ? (
                                        <b>Auction Closed</b>
                                    ) : item?.user_id == user?.id ? (
                                        <p>You cannot bid your own product!</p>
                                    ) : global.pluginConfiguration?.make_an_offer?.enable &&
                                      item?.make_an_offer_io &&
                                      props.upcomming ? (
                                        <>
                                            <MakeAnoffer item={item} user={user} />
                                        </>
                                    ) : global.pluginConfiguration?.reverse_auction?.enable &&
                                      item.content_head5 == 1 ? (
                                        <>
                                            {new Date(item.date_added) > new Date() ? (
                                                <p>Wait until auction starts!</p>
                                            ) : (
                                                <Biddingforwardandreverse
                                                    theme="multiseller"
                                                    data={item}
                                                    hard="1"
                                                    is_registered={props.is_registered}
                                                    private_auction={props.private_auction}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <div className="d-flex flex-wrap subLotLine">
                                            {/* Temporary fix for hiding the hard bid. Need to add the ability to update it from the admin */}
                                            {global.storeDetails.site_id != 'AS0182' &&
                                            global.storeDetails.site_id != 'AS0201' ? (
                                                <div className="row flex-nowrap slAct">
                                                    <div className="d-flex flex-wrap actCnt">
                                                        {item.auction && isAuthenticated ? (
                                                            <>
                                                                {global.storeConfigration?.hard_bid
                                                                    ?.value == '1' && (
                                                                    <Bidding
                                                                        data={item}
                                                                        hard="1"
                                                                        audio={props.audio}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="row flex-nowrap slAct">
                                                {item.auction ? (
                                                    <>
                                                        {global.storeConfigration?.proxy_bid
                                                            ?.value == '1' && (
                                                            <Bidding
                                                                data={item}
                                                                hard="0"
                                                                audio={props.audio}
                                                            />
                                                        )}
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className="row flex-nowrap slAct">
                                                {(parseInt(item.buynow) == 1 &&
                                                    parseInt(item.auction) == 0) ||
                                                (parseInt(item.wprice) <= parseInt(item.bprice) &&
                                                    parseInt(item.buynow) == 1 &&
                                                    parseInt(item.auction) == 1) ? (
                                                    <AddToCart
                                                        price={item.bprice}
                                                        id={item.id}
                                                        qty={item.qty}
                                                    />
                                                ) : null}
                                            </div>
                                            {getBidText(item) && (
                                                <p
                                                    className={`text-center ${`badgeStatus ${
                                                        item?.bidtopstatus?.includes('won')
                                                            ? 'Won'
                                                            : item?.bidtopstatus?.includes('lost')
                                                            ? 'Lost'
                                                            : item?.bidtopstatus?.includes(
                                                                  'winning',
                                                              )
                                                            ? 'Winning'
                                                            : item?.bidtopstatus?.includes('outbid')
                                                            ? 'Outbid'
                                                            : getBidText(item)
                                                    }`}`}
                                                >
                                                    {item?.bidtopstatus?.includes('won')
                                                        ? 'Won'
                                                        : item?.bidtopstatus?.includes('lost')
                                                        ? 'Lost'
                                                        : item?.bidtopstatus?.includes('winning')
                                                        ? 'Winning'
                                                        : item?.bidtopstatus?.includes('outbid')
                                                        ? 'Outbid'
                                                        : getBidText(item)}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* <PaginationComponent
                onPageChange={(pageNumber) => {
                    document.getElementById('sublot').scrollIntoView()
                    props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                }}
                totalProductCount={props.totalProductCount}
                currentPage={props.currentPage}
                resultToShow={props.resultToShow}
            /> */}
            <Bidhistory
                classNames="bidHstryModal"
                open={Object.keys(selectedHistory).length !== 0}
                value={selectedHistory}
                serverTime={props.serverTimeSocket}
                handleClose={() => openHistory({})}
            />
        </>
    )
}

export default TableView
